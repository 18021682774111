// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_main_2xcRE{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap;flex-flow:column nowrap;margin:0 auto;min-height:100vh;overflow:visible;position:static}.App_main_2xcRE a{text-decoration:none;color:#919191;-webkit-transition:color .5s ease;transition:color .5s ease}.App_main_2xcRE a.router-link-active,.App_main_2xcRE a.router-link-exact-active,.App_main_2xcRE a:hover{color:var(--primary-5)}.App_content_pIJBB{-webkit-box-flex:1;-ms-flex:1;flex:1}.App_footer_31xbU{font-size:.8rem;margin-top:calc(1.325rem + .9vw);display:-webkit-box;display:-ms-flexbox;display:flex;border-top:1px solid var(--primary-1);color:var(--secondary);-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:.5rem 0}@media(min-width:1200px){.App_footer_31xbU{margin-top:2rem}}.App_footer_31xbU.App_center_3dI1f{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.App_footer_31xbU>span>a{margin-left:.5rem}.App_countdown_oKwFo{left:0;position:fixed;top:0}.App_title_382G7{font-size:1.25rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:var(--primary);display:-webkit-box;display:-ms-flexbox;display:flex;font-weight:500;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;line-height:1.2;margin:0;text-align:center}.App_title_382G7 .App_alarm_3pwLU{font-size:calc(1.275rem + .3vw);color:var(--success);margin:0 .5rem}@media(min-width:1200px){.App_title_382G7 .App_alarm_3pwLU{font-size:1.5rem}}", ""]);
// Exports
exports.locals = {
	"main": "App_main_2xcRE",
	"content": "App_content_pIJBB",
	"footer": "App_footer_31xbU",
	"center": "App_center_3dI1f",
	"countdown": "App_countdown_oKwFo",
	"title": "App_title_382G7",
	"alarm": "App_alarm_3pwLU"
};
module.exports = exports;
