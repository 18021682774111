<template>
  <section :class="$style.balance">
    <a-card :bordered="false" size="small" type="inner">
      <template slot="title">
        <a-icon type="bank" theme="filled" />
        {{ $t("Balance - Crypto") }}
      </template>
      <template slot="extra">
        <a-button type="link" size="small" @click="onDepositClick">
          {{ $t("Deposit") }}
        </a-button>
        <a-button
          type="link"
          size="small"
          @click="$router.push({ name: 'trade.withdraw.crypto' })"
        >
          {{ $t("Withdraw") }}
        </a-button>
        <a-button
          type="link"
          shape="circle"
          size="small"
          icon="sync"
          :loading="isLoading"
          @click="fetchData()"
        />
      </template>
      <a-card-grid
        v-for="{ name, code, value, value_exchange, img } in namedCrypto"
        :key="code"
        :hoverable="false"
        :class="$style.cardCrypto"
      >
        <h6>
          {{ name }}<br />
          <span>{{ code }}</span>
        </h6>
        <strong v-if="value_exchange">{{ value_exchange.amount }}</strong>
        <em v-if="value"> {{ value.amount }}</em>
        <img v-if="code" :class="$style.img" :src="img" />
        <a-button
          type="link"
          size="small"
          icon="right"
          :class="$style.link"
          @click="onCardClick(code)"
        />
      </a-card-grid>
    </a-card>
    <a-card :bordered="false" size="small" type="inner">
      <template slot="title">
        <a-icon type="wallet" theme="filled" />
        {{ $t("Balance - Fiat") }}
      </template>
      <template slot="extra">
        <a-button
          type="link"
          size="small"
          @click="$router.push({ name: 'trade.deposit' })"
        >
          {{ $t("Deposit") }}
        </a-button>
        <a-button
          type="link"
          size="small"
          @click="$router.push({ name: 'trade.withdraw.fiat' })"
        >
          {{ $t("Withdraw") }}
        </a-button>
        <a-button
          type="link"
          shape="circle"
          size="small"
          icon="sync"
          :loading="isLoading"
          @click="fetchData()"
        />
      </template>
      <a-card-grid
        v-for="{ name, code, value } in namedFiat"
        :key="code"
        :hoverable="false"
        :class="$style.cardFiat"
      >
        <h6>
          {{ name }}<br />
          <span>{{ code }}</span>
        </h6>
        <strong>{{ value.amount }}</strong>
      </a-card-grid>
    </a-card>

    <a-card :bordered="false" size="small" type="inner">
      <span :class="$style.tip">
        <a-icon type="bulb" theme="filled" />
        {{ $t("balance_exchange_tip") }}
      </span>
    </a-card>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Button, Card, Icon } from "ant-design-vue";
import { cryptoConfig } from "@/utils";
import { getBalance } from "@/api";

const blank = { name: "", code: "", value: {} };

export default {
  name: "BalanceCard",
  data() {
    return {
      isLoading: false,
      balance: {
        crypto_exchange: {},
        crypto: {},
        fiat: {}
      }
    };
  },
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [Card.Grid.name]: Card.Grid,
    [Icon.name]: Icon
  },
  computed: {
    ...mapState({
      isGlobalLoading: state => state.isLoading
    }),
    ...mapGetters(["urlAssets", "urlDeposit"]),
    namedCrypto() {
      const { order, name, img } = cryptoConfig;
      const cryptoCode = [
        ...new Set([
          ...Object.keys(this.balance.crypto_exchange || {}),
          ...Object.keys(this.balance.crypto || {})
        ])
      ].sort((a, b) => {
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);
        if (indexA < 0) {
          return 1;
        } else if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      });
      const arr = cryptoCode.map(key => {
        const { crypto_exchange = {}, crypto = {} } = this.balance;
        return {
          name: name[key] || key,
          code: key,
          value: crypto[key],
          value_exchange: crypto_exchange[key],
          img: img[key]
        };
      });
      if (arr.length % 2 === 1) {
        return [...arr, { ...blank }];
      } else {
        return arr;
      }
    },
    namedFiat() {
      const map = {
        USD: "US Dollar",
        EUR: "Euro"
      };
      const arr = Object.entries(this.balance.fiat || {}).map(
        ([key, value]) => ({
          name: map[key] || key,
          code: key,
          value
        })
      );
      if (arr.length % 2 === 1) {
        return [...arr, { ...blank }];
      } else {
        return arr;
      }
    }
  },
  watch: {
    isGlobalLoading: {
      async handler(val) {
        if (!val) {
          await this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    onDepositClick() {
      if (this.urlAssets === "INTERNAL") {
        this.$router.push({ name: "trade.wallet" });
      } else {
        window.open(this.urlAssets, "LegendSDK_Crypto_Despsit");
      }
    },
    onCardClick(code) {
      if (code !== "") {
        if (this.urlDeposit === "INTERNAL") {
          this.$router.push({
            name: "trade.wallet",
            params: { activeKey: code }
          });
        } else {
          const baseUrl = this.urlDeposit;
          window.open(
            baseUrl.replace(/{{SYMBOL}}/, code),
            "LegendSDK_Crypto_Despsit"
          );
        }
      }
    },
    async fetchData() {
      this.isLoading = true;
      const balance = await getBalance();
      this.balance = balance;
      this.isLoading = false;
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.balance {
  .cardCrypto,
  .cardFiat {
    @include padding-bottom(0.5rem);
    @include padding-right(0.75rem);
    @include padding-top(0.5rem);

    position: relative;
    &:nth-child(1) {
      box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8;
    }
    &:nth-child(2) {
      box-shadow: 0 1px 0 0 #e8e8e8;
    }
    &:nth-child(2n + 3) {
      box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8,
        0 1px 0 0 #e8e8e8 inset;
    }
    &:nth-child(2n + 4) {
      box-shadow: 0 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset,
        0 1px 0 0 #e8e8e8 inset;
    }
  }
  .cardCrypto {
    @include padding-left(4.25rem);
  }
  .cardFiat {
    @include padding-left(0.75rem);
  }
  .img,
  .link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .img {
    left: 1rem;
    height: auto;
    width: 2.5rem;
  }
  .link {
    right: 0.25rem;
  }
  .tip {
    @include rfs(0.8rem);
    color: var(--legend);
  }

  a {
    color: var(--primary);
  }

  :global {
    .ant-btn-link.ant-btn-sm {
      @include rfs(0.8rem);
    }

    .ant-card {
      box-shadow: 0 0 20px rgba(235, 240, 245, 0.5);
    }

    .ant-card-head {
      border-bottom: 0 none;
    }

    .ant-card-grid {
      min-height: 4.5rem;
      width: 50%;
      h6 {
        @include font-size(0.8rem);
        @include margin-bottom(0.2rem);

        color: var(--primary);
        font-weight: 600;
        line-height: 1.2;
        span {
          font-weight: 400;
        }
      }
      strong,
      em {
        @include font-size(0.9rem);

        font-style: normal;
        font-weight: 500;
        opacity: 0.9;
      }
      strong {
        color: var(--dark);
      }
      em {
        color: var(--legend);
      }
    }
  }
}
</style>
