<template>
  <main :class="$style.main" :style="inlineMainStyle">
    <transition>
      <keep-alive>
        <router-view :class="$style.content">
          <slot name="title" slot="title" />
        </router-view>
      </keep-alive>
    </transition>
    <footer :class="{ [$style.footer]: true, [$style.center]: !showPoweredBy }">
      <powered-by v-if="showPoweredBy" />
      <i18n
        v-if="!clientInfo.noLegend"
        v-page-guide="'debug.guide_messages[0]'"
        path="Contact us if you need assistance"
      >
        <a :href="`mailto:${clientInfo.email}`"
          ><strong>{{ clientInfo.email }}</strong></a
        >
      </i18n>
    </footer>

    <debug-panel v-if="isDemo || isDev" />
    <v-page-guide v-model="showGuide" />
    <vac
      v-show="isDemo || isDev"
      ref="countdown"
      :class="$style.countdown"
      :autoStart="false"
      :left-time="expiresIn * 1000"
      @finish="onCountdownFinish"
    >
      <span slot="prev">session expires in: </span>
      <span slot="process" slot-scope="{ timeObj }">
        {{ timeObj.ceil.s }}
      </span>
      <span slot="finish">Expired!</span>
    </vac>

    <a-modal
      v-model="isModalVisible"
      :cancelText="$t('Cancel')"
      :okText="$t('Okay, keep me connected.')"
      :getContainer="() => $root.$el"
      :closable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <h5 :class="$style.title" slot="title">
        <a-icon :class="$style.alarm" :component="AlarmClock" />
        {{ $t("Session Time-out") }}
      </h5>
      <p>{{ $t("session_messages[0]") }}</p>
    </a-modal>
  </main>
</template>

<script>
import "./bootstrap";

// import NProgress from "nprogress";
import { mapState, mapGetters, mapActions } from "vuex";
import { Icon, Message, Modal, Notification } from "ant-design-vue";
import Color from "color";
import i18n from "./i18n";
import store from "./store";
import router from "./router";
import DebugPanel from "@/components/DebugPanel.vue";
import VPageGuide from "@/components/VPageGuide.vue";
import PoweredBy from "@/components/PoweredBy.vue";
import AlarmClock from "@/assets/img/alarm-clock.svg?inline";
import { injectGTM, sizeList, apiConfig, displayErrors } from "@/utils";
import {
  api as apiTrade,
  getPairs as getPairsTrade,
  sign,
  authorize
} from "@/api";
import { api as apiKyc, getPairs as getPairsKyc } from "@/api/kyc";
import "@/vee-validate";
export default {
  name: "LegendTrade",
  i18n,
  store,
  router,
  components: {
    [Icon.name]: Icon,
    [Modal.name]: Modal,
    DebugPanel,
    VPageGuide,
    PoweredBy
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    remote: {
      type: String,
      default: ""
    },
    lang: {
      type: String,
      default: "en",
      validator: value => {
        // supported ISO 639 language codes
        return i18n.availableLocales.includes(value.toLowerCase());
      }
    },
    email: {
      type: String,
      default: "support@legendtrading.com"
    },
    noLegend: {
      type: Boolean,
      default: false
    },
    uiSize: {
      type: String,
      default: "default",
      validator: value => sizeList.includes(value)
    },
    primaryColor: {
      type: String,
      default: ""
    },
    urlStyle: {
      type: String,
      default: ""
    },
    urlAssets: {
      type: String,
      default: ""
    },
    urlDeposit: {
      type: String,
      default: ""
    },
    timestamp: {
      type: String,
      default: "1587364131"
    },
    signature: {
      type: String,
      default: "ArFlDTZJHOYFUTG8NceXk8uDFyCD0WxT7iEuf2DvI1Y="
    },
    appId: {
      type: String,
      default: "Bitrue"
    },
    appUid: {
      type: String,
      default:
        "13212Bd1ZZ5VO98z8Gf/z39m6RjSxhdOV9nW+8+6j9j+ZV2PumX1KhoOqxM+Y9Uk++elE:OTg3NjVmZWRjYmE0MzIxMA=="
    },
    appUrl: {
      type: String,
      default: "https://www.bitrue.com/"
    },
    appKey: {
      type: String,
      default: "Aw4fHfWZwFFi9q2l"
    }
  },
  data() {
    return {
      showGuide: false,
      isModalVisible: false,
      AlarmClock
    };
  },
  computed: {
    ...mapState([
      "themeColor",
      "clientInfo",
      "token",
      "expiresIn",
      "kycStatus"
    ]),
    ...mapGetters(["isAccountEnabled", "isAccountPending"]),
    showPoweredBy() {
      return !["trade.index"].includes(this.$route.name);
    },
    isLocal() {
      return apiConfig.reHostname.test(window.location.hostname);
    },
    isDev() {
      return this.mode === "dev";
    },
    isSandbox() {
      return this.mode === "sandbox";
    },
    isDemo() {
      return this.mode === "demo";
    },
    color: {
      get() {
        return this.themeColor;
      },
      set(val) {
        this.setThemeColor(val);
      }
    },
    validLang() {
      // TODO: fallback with navigator.languages
      return i18n.availableLocales.includes(this.lang.toLowerCase())
        ? this.lang
        : i18n.fallbackLocale;
    },
    inlineMainStyle() {
      const primaryColor = Color(this.color);
      return {
        "--primary": this.color,
        // "--legend": this.color,
        "--primary-1": primaryColor
          .lighten(0.75)
          .rgb()
          .string(),
        "--primary-2": primaryColor
          .lighten(0.67)
          .rgb()
          .string(),
        "--primary-3": primaryColor
          .lighten(0.5)
          .rgb()
          .string(),
        "--primary-5": primaryColor
          .lighten(0.25)
          .rgb()
          .string(),
        "--primary-7": primaryColor
          .darken(0.25)
          .rgb()
          .string(),
        "--primary-a20": primaryColor
          .fade(0.8)
          .rgb()
          .string(),
        "--legend-light": primaryColor
          .lighten(0.33)
          .fade(0.9)
          .rgb()
          .string(),
        "--legend-dark": primaryColor
          .saturate(-0.67)
          .fade(0.9)
          .rgb()
          .string()
      };
    }
  },
  watch: {
    async isAccountEnabled(val) {
      if (!val) {
        return;
      } else if (this.isLocal || this.isDev || this.isDemo) {
        await this.getAuth();
      }
    },
    expiresIn(val) {
      this.$nextTick(() => {
        const { countdown } = this.$refs;
        if (val > 0) {
          countdown.startCountdown(true);
        } else {
          countdown.stopCountdown();
        }
      });
    }
  },
  methods: {
    ...mapActions([
      "toggleLoading",
      "toggleMaintenance",
      "updateKycStatus",
      "updateAccountEmail",
      "updateAccountType",
      "updateExpiresIn",
      "updateClientInfo",
      "setThemeColor",
      "setToken",
      "setPairs"
    ]),
    setupEnv() {
      if (process.env.NODE_ENV === "development") {
        // respect env config during develpoment
      } else if (this.isLocal || this.isDev) {
        // sandbox not work during local build
        apiConfig.env = "development";
        apiConfig.remote = this.remote;
        apiTrade.defaults.baseURL = apiConfig.baseUrlTrade;
        apiKyc.defaults.baseURL = apiConfig.baseUrlKyc;
      } else if (this.isSandbox || this.isDemo) {
        apiConfig.env = "sandbox";
        apiTrade.defaults.baseURL = apiConfig.baseUrlTrade;
        apiKyc.defaults.baseURL = apiConfig.baseUrlKyc;
      }
    },
    async getSign() {
      if (
        this.isLocal ||
        this.isDev ||
        (this.isDemo && process.env.NODE_ENV === "development")
      ) {
        const status = this.kycStatus.toLowerCase();
        const data = await sign({ status });
        this.updateClientInfo({
          timestamp: data.timestamp,
          signature: data.signature,
          appId: data["APP-ID"],
          appKey: data["APP-KEY"],
          appUid: data["APP-UID"],
          appUrl: data["APP-URL"]
        });
      }
      const {
        access_token,
        expires_in,
        kyc_status,
        account_email,
        account_type
      } = await authorize(this.clientInfo);
      this.updateKycStatus(kyc_status);
      if (account_email) {
        this.updateAccountEmail(account_email);
      }
      this.updateAccountType(account_type);
      this.setToken(access_token);
      this.updateExpiresIn(expires_in);
    },
    async getAuth() {
      try {
        this.toggleLoading(true);
        if (
          process.env.NODE_ENV !== "development" &&
          this.isDemo &&
          this.isAccountEnabled &&
          !this.isDev
        ) {
          apiConfig.token = process.env.VUE_APP_DEMO_TOKEN;
          this.setToken(process.env.VUE_APP_DEMO_TOKEN);
          this.updateExpiresIn(1800);
        } else {
          await this.getSign();
        }
        const pairs = await this.getPairs();
        this.setPairs(pairs);
      } catch (err) {
        if (err.response) {
          const { maintenance_mode } = err.response.data;
          if (maintenance_mode) {
            this.toggleMaintenance(true);
            this.showGuide = false;
            router.push("/maintain");
          }
        }
        displayErrors(err);
      } finally {
        this.toggleLoading(false);
      }
    },
    getPairs() {
      return this.isAccountEnabled ? getPairsTrade() : getPairsKyc();
    },
    injectStyle(url) {
      const $link = document.createElement("link");
      $link.rel = "stylesheet";
      $link.href = url;
      const $rootnode = this.$el.getRootNode();
      if ($rootnode instanceof window.ShadowRoot) {
        // shadow dom
        $rootnode.insertBefore($link, this.$el);
      } else {
        // html
        document.head.appendChild($link);
      }
    },
    onCountdownFinish() {
      this.isModalVisible = true;
    },
    handleOk() {
      window.location.reload();
    },
    handleCancel() {
      window.close();
    }
  },
  created() {
    // put app props into store
    this.updateClientInfo(this.$props);
    this.$i18n.locale = this.validLang;
    if (this.isDemo) {
      this.showGuide = true;
    }
    if (this.primaryColor) {
      try {
        const c = Color(this.primaryColor);
        this.color = c.rgb().string();
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
    this.setupEnv();
    this.getAuth();
  },
  mounted() {
    if (this.urlStyle) {
      this.$nextTick(() => {
        this.injectStyle(this.urlStyle);
      });
    }
    // FIXME: nprogress not work with ShadowDom
    // NProgress.configure({
    //   parent: `.${this.$style.main}`
    // });
    Message.config({
      getContainer: () => this.$root.$el
    });
    Notification.config({
      getContainer: () => this.$root.$el,
      placement: "bottomRight"
    });

    // inject google tagmanager
    injectGTM("jssdk-v1");
  }
};
</script>

<style src="nprogress/nprogress.css"></style>
<style lang="less">
@import "~ant-design-vue/dist/antd.less";
@import "./theme.less";

.@{dialog-prefix-cls} {
  &-footer {
    & > div {
      align-items: center;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: center;
    }
    button {
      margin: 0 1em;
      min-width: 10em;
    }
  }
}
.@{notification-prefix-cls} {
  &-notice {
    &-message::first-letter,
    &-description::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
<style lang="scss" src="./scss/host.scss"></style>
<style lang="scss" module>
@import "~rfs/scss";

.main {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  min-height: 100vh;
  overflow: visible;
  position: static;

  a {
    text-decoration: none;
    color: #919191;
    transition: color 500ms ease;

    &:hover,
    &:global(.router-link-active),
    &:global(.router-link-exact-active) {
      color: var(--primary-5);
    }
  }
}
.content {
  flex: 1;
}
.footer {
  @include rfs(0.8rem);
  @include margin-top(2rem);

  display: flex;
  border-top: 1px solid var(--primary-1);
  color: var(--secondary);
  justify-content: space-between;
  padding: 0.5rem 0;

  &.center {
    justify-content: center;
  }
  & > span > a {
    margin-left: 0.5rem;
  }
}
.countdown {
  left: 0;
  position: fixed;
  top: 0;
}
.title {
  @include rfs(1.25rem);

  align-items: center;
  color: var(--primary);
  display: flex;
  font-weight: 500;
  justify-content: center;
  line-height: 1.2;
  margin: 0;
  text-align: center;

  .alarm {
    @include rfs(1.5rem);

    color: var(--success);
    margin: 0 0.5rem;
  }
}
</style>
