import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Mock from "mockjs";
import MockAdapter from "axios-mock-adapter";
import arrLogFunc, { apiConfig } from "../utils";
import {
  tmplRfq,
  tmplTrade,
  tmplTradeHistory,
  tmplSettleHistory,
  tmplBalance,
  tmplBankList,
  tmplPairs,
  tmplUpdateBankInfo,
  tmplGetCurrencies,
  tmplGetBankInfo,
  tmplGetWirecode
} from "./mock";

let configPairs = null;

const initMockAdapter = api => {
  if (process.env.NODE_ENV === "production") {
    return;
  }

  api.interceptors.response.use(...arrLogFunc);

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  // mock.onPost('/trade').networkErrorOnce();
  mock
    .onGet("/users")
    .reply(200, {
      users: [{ id: 1, name: "John Smith" }]
    })
    .onGet("/pairs")
    .reply(200, Mock.mock(tmplPairs))
    .onPost("/rfq", { pair: "BTCUSD", side: "buy", quantity: 1.1 })
    .reply(config => {
      const { pair, side, quantity } = JSON.parse(config.data);
      return [200, { ...Mock.mock(tmplRfq), pair, side, quantity }];
    })
    .onPost("/trade")
    .reply(200, Mock.mock(tmplTrade))
    .onGet("/tradehistory")
    .reply(config => {
      const { offset, limit } = config.params;
      return [
        200,
        Mock.mock(tmplTradeHistory).array.slice(offset, offset + limit)
      ];
    })
    .onPost("/rfq", { pair: "BTCUSD", side: "buy", quantity: 1.1 })
    .reply(config => {
      const { pair, side, quantity } = JSON.parse(config.data);
      return [200, { ...Mock.mock(tmplRfq), pair, side, quantity }];
    })
    .onGet("/balance")
    .reply(200, Mock.mock(tmplBalance))
    .onGet("/bank/list")
    .reply(200, Mock.mock(tmplBankList))
    .onGet(/\/settlement\/history/)
    .reply(config => {
      const { offset, limit } = config.params;
      return [
        200,
        Mock.mock(tmplSettleHistory).array.slice(offset, offset + limit)
      ];
    })
    .onPost("/bank/update")
    .reply(200, Mock.mock(tmplUpdateBankInfo))
    .onPost("/settlement")
    .reply(200, Mock.mock(tmplUpdateBankInfo))
    .onGet("/currencies")
    .reply(200, Mock.mock(tmplGetCurrencies))
    .onGet("/bank")
    .reply(200, Mock.mock(tmplGetBankInfo))
    .onGet("/wirecode")
    .reply(200, Mock.mock(tmplGetWirecode))
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: apiConfig.baseUrlTrade,
  headers: {
    Authorization: `Bearer ${apiConfig.token}`,
    Accept: "application/json"
  },
  timeout: 180000
});

if (apiConfig.reHostname.test(window.location.hostname)) {
  initMockAdapter(api);
}

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${apiConfig.token}`;
  return config;
});

const getUsers = () => api.get("/users");

const sign = async ({ json = 1, uid = null, status = "approved" } = {}) => {
  const resp = await api.get("/sign", {
    params: { json, uid, status }
  });
  return resp.data;
};

const authorize = async ({
  timestamp,
  signature,
  appKey,
  appId,
  appUrl,
  appUid
}) => {
  const resp = await api.post("/authorize", {
    timestamp,
    signature,
    "APP-KEY": appKey,
    "APP-ID": appId,
    "APP-URL": appUrl,
    "APP-UID": appUid
  });
  apiConfig.token = resp.data.access_token;
  return resp.data;
};

const getPairs = async () => {
  if (!configPairs) {
    const resp = await api.get("/pairs");
    configPairs = resp.data;
  }
  return configPairs;
};

const rfq = async ({ pair = "BTCUSD", side = "buy", quantity = 1 }) => {
  const resp = await api.post("/rfq", {
    client_rfq_id: uuidv4(),
    pair,
    side,
    quantity
  });
  return resp.data;
};

const trade = async ({
  rfq_id = "",
  side = "buy",
  quantity = 0,
  pair = "BTCUSD",
  price = 0
}) => {
  const resp = await api.post("/trade", {
    rfq_id,
    side,
    quantity,
    pair,
    price
  });
  if (resp.data.error_code) {
    // error with 200 response code
    const error = new Error(resp.data.error_code);
    error.response = resp;
    throw error;
  }
  return resp.data;
};

const getTradeHistory = async ({
  startdate = "1970-01-01T00:00:00.000000Z",
  enddate = "",
  limit = 5000,
  offset = 0,
  order = "desc"
}) => {
  const resp = await api.get("/tradehistory", {
    params: { offset, limit, startdate, enddate, order }
  });
  return resp.data;
};

const getBalance = async () => {
  const resp = await api.get("/balance");
  return resp.data;
};

const getCurrencies = async () => {
  const resp = await api.get("/currencies");
  return resp.data;
};

const getBankInfo = async ({ currency = "USD" }) => {
  const resp = await api.get("/bank", {
    params: { currency }
  });
  return resp.data;
};

const getWirecode = async () => {
  const resp = await api.get("/wirecode");
  return resp.data;
};

const getTbwallet = async (asset = "") => {
  const resp = await api.get("/tbwallet", {
    params: { asset }
  });
  return resp.data;
};

const addSettlement = async ({ amount_pay_to = 0, asset_pay_to = "USD" }) => {
  const resp = await api.post("/settlement", {
    amount_pay_to,
    asset_pay_to
  });
  return resp.data;
};

const getSettlementHistory = async ({
  startdate = "1970-01-01T00:00:00.000000Z",
  enddate = "",
  limit = 5000,
  offset = 0,
  order = "desc"
}) => {
  const resp = await api.get("/settlement/history", {
    params: { offset, limit, startdate, enddate, order }
  });
  return resp.data;
};

const getBankList = async () => {
  const resp = await api.get("/bank/list");
  return resp.data;
};

const updateBankInfo = async ({
  account_name = "",
  account_address = "",
  bank_name = "",
  bank_address = "",
  bank_country = "",
  account_number = "",
  routing_number = "",
  bank_swift = ""
}) => {
  const resp = await api.post("/bank/update", {
    account_name,
    account_address,
    bank_name,
    bank_address,
    bank_country,
    account_number,
    routing_number,
    bank_swift
  });
  return resp.data;
};

const getFees = async () => {
  const resp = await api.get("/fees");
  return resp.data;
};

export {
  api,
  sign,
  authorize,
  rfq,
  getUsers,
  getPairs,
  trade,
  getTradeHistory,
  getBalance,
  getCurrencies,
  getBankInfo,
  getWirecode,
  getTbwallet,
  addSettlement,
  getSettlementHistory,
  getBankList,
  updateBankInfo,
  getFees
};
