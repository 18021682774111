<template>
  <section :class="$style.faq">
    <h4>
      {{ $t("Why OTC?") }}
    </h4>

    <ul :class="{ [$style.why]: true, [$style.legend]: !noLegend }">
      <li v-t="'why_otc[0]'"></li>
      <li v-if="$te(`why_otc[2]['${appId}'][1]`)">
        {{ $t(`why_otc[2]["${appId}"][1]`) }}
      </li>
      <li v-else>{{ $t("why_otc[1]") }}</li>
    </ul>

    <h4>
      <span @click="toggleActivePanel()">{{ $t("FAQ") }}</span>
    </h4>
    <a-collapse
      accordion
      :activeKey="activeKey"
      :defaultActiveKey="defaultActiveKey"
      :bordered="false"
    >
      <template v-slot:expandIcon></template>
      <a-collapse-panel key="faq" :showArrow="false">
        <faq-content />
      </a-collapse-panel>
    </a-collapse>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { Button, Collapse, Icon } from "ant-design-vue";
import FaqContent from "@/components/FaqContent.vue";

export default {
  name: "FaqWhy",
  components: {
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [Collapse.Panel.name]: Collapse.Panel,
    [Icon.name]: Icon,
    FaqContent
  },
  data() {
    return {
      activeKey: ""
    };
  },
  computed: {
    ...mapState({
      appId: state => state.clientInfo.appId,
      noLegend: state => state.clientInfo.noLegend
    }),
    defaultActiveKey() {
      return this.noLegend ? "faq" : "";
    }
  },
  methods: {
    toggleActivePanel() {
      if (this.activeKey) {
        this.activeKey = "";
      } else {
        this.activeKey = "faq";
      }
    }
  },
  created() {
    this.activeKey = this.noLegend ? "faq" : "";
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.faq {
  @include padding-top(2rem);

  & > h4 {
    font-weight: 600;
    margin-top: 2rem;
    span {
      color: var(--primary);
      cursor: pointer;
      &:hover,
      &:focus {
        color: var(--primary-5);
      }

      &:active,
      &.active {
        color: var(--primary-7);
      }
    }
  }
  :global {
    .ant-collapse-borderless {
      background-color: transparent;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 0 none;
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-header {
      display: none;
    }
  }
}
.why {
  list-style-position: inside;
  padding: 1rem 0 0;

  &.legend {
    list-style-image: url(../assets/img/list-item.svg);
  }

  li {
    margin-top: 0.25rem;
  }
}
</style>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
