// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FaqContent_fee_HE_-n{float:right;margin-left:.5rem}.FaqContent_fee_HE_-n table{border-collapse:collapse}.FaqContent_fee_HE_-n td,.FaqContent_fee_HE_-n th{border:1px solid var(--gray);padding:.25rem .5rem}.FaqContent_fee_HE_-n td{text-align:right}.FaqContent_email_2sLlO{border-bottom:1px solid currentColor;font-weight:600}", ""]);
// Exports
exports.locals = {
	"fee": "FaqContent_fee_HE_-n",
	"email": "FaqContent_email_2sLlO"
};
module.exports = exports;
