import Vue from "vue";
import vueAwesomeCountdown from "vue-awesome-countdown";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { DateTime } from "luxon";

Vue.use(vueAwesomeCountdown, "vac");

extend("required", required);
extend("positive", value => {
  const float = Number.parseFloat(value);
  return !Number.isNaN(float) && float > 0;
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

Vue.directive("page-guide", {
  bind(el, binding) {
    el.setAttribute("v-page-guide", binding.value);

    const modifiers = Object.getOwnPropertyNames(binding.modifiers);
    if (modifiers.length > 0) {
      el.setAttribute("v-page-guide-placement", modifiers[0]);
    }
  }
});

Vue.filter("formatNumber", (number, { pair }, config, type) => {
  const [quantity, price] = config[pair].split(" / ");
  const unit = { quantity, price }[type];
  const num = number;
  return `${num} ${unit}`;
});
Vue.filter("formatBalance", (number, fixed = 2) =>
  Number.parseFloat(number).toFixed(fixed)
);
Vue.filter("formatDate", datetime => {
  const dt = DateTime.fromISO(datetime);
  return dt.toLocaleString(DateTime.DATE_SHORT);
});
Vue.filter("formatTime", datetime => {
  const dt = DateTime.fromISO(datetime);
  return dt.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
});
Vue.filter("formatDateSQL", datetime => {
  const dt = DateTime.fromSQL(datetime);
  return dt.toLocaleString(DateTime.DATE_SHORT);
});
Vue.filter("formatTimeSQL", datetime => {
  const dt = DateTime.fromSQL(datetime);
  return dt.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
});
Vue.filter("formatDatetime", datetime => {
  if (!datetime) {
    return "";
  } else {
    const dt = DateTime.fromISO(datetime);
    return dt.toFormat("yyyy-MM-dd HH:mm:ss");
  }
});
