<template>
  <section>
    <a-card :class="$style.cardRfq" :bordered="false">
      <a-select
        v-model="currentPair"
        v-page-guide="'debug.guide_messages[4]'"
        :size="uiSize"
        :loading="isLoading"
        :dropdownMatchSelectWidth="false"
        :getPopupContainer="() => $root.$el"
      >
        <a-select-option v-for="(pair, name) in pairs" :key="name">{{
          pair
        }}</a-select-option>
      </a-select>
      <a-radio-group v-model="side" :size="uiSize" buttonStyle="solid">
        <a-radio-button value="sell">{{ $t("SELL") }}</a-radio-button>
        <a-radio-button value="buy">{{ $t("BUY") }}</a-radio-button>
      </a-radio-group>
      <ValidationProvider
        ref="validation"
        mode="eager"
        name="quantity"
        rules="required|positive"
        v-slot="{ classes }"
      >
        <a-input
          :value="quantity"
          :class="classes"
          :addonAfter="token"
          :size="uiSize"
          @change="onQuantityChange"
          @pressEnter="handleRfq()"
        />
      </ValidationProvider>
      <a-button
        block
        :size="uiSize"
        type="primary"
        :loading="isTrading"
        @click="handleRfq()"
        >{{ $t("REQUEST A QUOTE") }}</a-button
      >
      <section v-if="isEnabled" :class="$style.link">
        <router-link :to="{ name: 'trade.history' }">
          {{ $t("Order History") }}
          <a-icon type="right" />
        </router-link>
      </section>
      <powered-by slot="actions" />
    </a-card>
    <a-modal
      :class="$style.modalReview"
      :zIndex="1100"
      :width="456"
      :visible="visibleReview"
      :confirmLoading="isTrading"
      :closable="false"
      :okText="okText"
      :cancelText="$t('Cancel')"
      :getContainer="() => $root.$el"
      @ok="handleTrade"
      @cancel="handleCancel"
    >
      <h5 :class="$style.modalTitle" slot="title">
        <a-icon :class="$style.textInfo" type="info-circle" />
        {{ $t("Review") }}
      </h5>
      <a-list size="small" :dataSource="listReview">
        <div
          :class="$style.itemReview"
          slot="renderItem"
          slot-scope="[key, value]"
        >
          <template v-if="key !== 'Expiry'">
            <strong>{{ $t(key) }}</strong>
            <span>{{ value }}</span>
          </template>
          <template v-else>
            <strong>&nbsp;</strong>
            <vac
              :class="$style.countdown"
              :left-time="value"
              :auto-start="false"
              ref="vacExpiry"
            >
              <template v-slot:process="{ timeObj }">
                <span>{{ timeObj.ceil.s }} {{ $t("seconds") }}</span>
              </template>
              <template v-slot:finish>
                <span :class="$style.countdown">{{ $t("expired") }}</span>
              </template>
            </vac>
          </template>
        </div>
      </a-list>
    </a-modal>
    <a-modal
      :class="$style.modalTrade"
      :zIndex="1100"
      :width="456"
      :visible="visibleTrade"
      :closable="false"
      :cancelButtonProps="{ hide: true }"
      :getContainer="() => $root.$el"
      @ok="handleTradeSuccess"
      @cancel="handleTradeSuccess"
    >
      <h5 :class="$style.modalTitle" slot="title">
        <a-icon :class="$style.textSuccess" type="check-circle" />
        {{ $t("Trade executed") }}
      </h5>
      <template slot="footer">
        <template v-if="isBuy">
          <span class="withdraw-msg"> {{ $t("Withdraw MSG") }} </span>
          <a-button :size="uiSize" type="primary" @click="handleWithdraw">
            {{ $t("Withdraw Amount") }} {{ quantity }} {{ token }}
          </a-button>
          <a-button :size="uiSize" type="primary" @click="handleWithdrawLater">
            {{ $t("Withdraw Later") }}
          </a-button>
        </template>
        <template v-else>
          <a-button :size="uiSize" type="primary" @click="handleTradeSuccess">
            {{ $t("OK") }}
          </a-button>
        </template>
      </template>
      <a-list size="small" :dataSource="listTrade">
        <div
          :class="$style.itemTrade"
          slot="renderItem"
          slot-scope="[key, value]"
        >
          <strong>{{ $t(key) }}</strong>
          <span>{{ value }}</span>
        </div>
      </a-list>
    </a-modal>
    <result-modal
      :modalResultProps="modalResultProps"
      @click-ok="errorModalOnOk"
    />
    <confirm-modal
      :confirmModalProps="confirmModalProps"
      @click-ok="confirmOnOk"
      @click-cancel="confirmOnCancel"
    />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { bignumber, evaluate, format } from "mathjs";
import { DateTime } from "luxon";
import {
  Alert,
  Button,
  Card,
  Icon,
  Input,
  List,
  Modal,
  Radio,
  Select
} from "ant-design-vue";
import PoweredBy from "@/components/PoweredBy.vue";
import { displayErrors } from "@/utils";
import { rfq as rfqTrade, trade, addSettlement } from "@/api";
import { rfq as rfqKyc } from "@/api/kyc";
import ResultModal from "@/components/ResultModal";
import ConfirmModal from "@/components/ConfirmModal";
export default {
  name: "TradeRfq",
  components: {
    [Alert.name]: Alert,
    [Button.name]: Button,
    [Card.name]: Card,
    [Icon.name]: Icon,
    [Input.name]: Input,
    [List.name]: List,
    [List.Item.name]: List.Item,
    [Modal.name]: Modal,
    [Radio.name]: Radio,
    [Radio.Button.name]: Radio.Button,
    [Radio.Group.name]: Radio.Group,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    PoweredBy,
    ResultModal,
    ConfirmModal
  },
  data() {
    return {
      isTrading: false,
      isWithdrawing: false,
      visibleReview: false,
      visibleTrade: false,
      currentPair: "",
      side: "buy",
      quantity: "",
      dataReview: {},
      dataTrade: {},
      expiry: 0,
      modalResultProps: {
        title: "",
        visible: false,
        message: "",
        type: ""
      },
      confirmModalProps: {
        title: "",
        visible: false,
        message: "",
        okText: "",
        cancelText: ""
      }
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
      uiSize: state => state.clientInfo.uiSize,
      pairs: state => state.pairs
    }),
    ...mapGetters({
      isEnabled: "isAccountEnabled",
      urlDeposit: "urlDeposit"
    }),
    unit() {
      const pair = this.pairs[this.currentPair];
      if (!pair) {
        return "";
      }
      const [buy, sell] = pair.split(" / ");
      return { buy, sell };
    },
    token() {
      return this.unit.buy;
    },
    fiat() {
      return this.unit.sell;
    },
    okText() {
      const { side, quantity, token } = this;
      const action = side.replace(/^\w/, char => char.toUpperCase());
      return `${this.$t(action)} ${quantity} ${token}`;
    },
    namesMap() {
      return [
        { name: "Market", value: ({ pair }) => pair },
        {
          name: "Side",
          value: ({ side = "buy" }) => this.$t(side.toUpperCase())
        },
        { name: "Price", value: ({ price = 0 }) => `${price} ${this.fiat}` },
        {
          name: "Amount",
          value: ({ quantity = 0 }) => `${quantity} ${this.token}`
        },
        {
          name: "Total",
          value: ({ quantity = 0, price = 0 }) => {
            const numTotal = evaluate("a * b", {
              a: bignumber(quantity),
              b: bignumber(price)
            });
            return `${format(numTotal, { notation: "fixed" })} ${this.fiat}`;
          }
        }
      ];
    },
    listReview() {
      const mapNames = [
        ...this.namesMap,
        { name: "Expiry", value: () => this.expiry }
      ];
      return mapNames.map(({ name, value }) => [name, value(this.dataReview)]);
    },
    listTrade() {
      return this.namesMap.map(({ name, value }) => [
        name,
        value(this.dataTrade)
      ]);
    },
    isBuy() {
      return this.side === "buy";
    }
  },
  watch: {
    pairs(val) {
      if (!Object.keys(val).includes(this.currentPair)) {
        this.currentPair = Object.keys(val)[0];
      }
    }
  },
  methods: {
    rfq(...args) {
      return this.isEnabled ? rfqTrade(...args) : rfqKyc(...args);
    },
    calcExpiry({ created, valid_until }) {
      try {
        const dt0 = DateTime.fromISO(created);
        const dt1 = DateTime.fromISO(valid_until);
        return Math.floor(dt1.diff(dt0, "milliseconds").milliseconds) || 15000;
      } catch (err) {
        /* eslint-disable-next-line */
        console.error(err);
        // expire in 15s
        return 15000;
      }
    },
    onQuantityChange(e) {
      const { value } = e.target;
      const re = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if ((!Number.isNaN(value) && re.test(value)) || value === "") {
        this.quantity = value;
      }
    },
    async handleRfq() {
      const { flags } = this.$refs.validation;
      if (flags.passed) {
        try {
          this.isTrading = true;
          const data = await this.rfq({
            pair: this.currentPair,
            side: this.side,
            quantity: Number.parseFloat(this.quantity)
          });
          this.dataReview = data;
          this.expiry = this.calcExpiry(data);
          this.visibleReview = true;
          this.$nextTick(() => {
            const vac = this.$refs.vacExpiry;
            vac && vac.startCountdown(true);
          });
        } catch (err) {
          displayErrors(err);
        } finally {
          this.isTrading = false;
        }
      }
    },
    async handleTrade() {
      try {
        const vac = this.$refs.vacExpiry;
        if (!this.isEnabled) {
          // account disabled
          this.modalResultProps = {
            visible: true,
            title: this.$t("Start trading"),
            message: this.$t("account_messages[0]"),
            type: "error"
          };
          this.visibleReview = false;
        } else if (vac.state !== "process") {
          // expired
          this.modalResultProps = {
            visible: true,
            title: this.$t("RFQ expired"),
            message: this.$t("Your quote expired. Please request a new quote"),
            type: "error"
          };
          this.visibleReview = false;
        } else {
          this.isTrading = true;
          const data = await trade(this.dataReview);
          this.dataTrade = data;
          this.visibleReview = false;
          await this.$nextTick();
          this.visibleTrade = true;
        }
      } catch (err) {
        this.visibleReview = false;
        if (err.message === "trade_size_too_small") {
          this.modalResultProps = {
            visible: true,
            title: this.$t("Trade failed"),
            message: err.response.data.message,
            type: "error"
          };
        } else if (err.message === "not_enough_balance_in_external") {
          // confirm navigation to deposit url
          this.confirmModalProps = {
            visible: true,
            title: this.$t("Insufficient Balance"),
            message: this.$t("insufficient_messages[0]", [this.token]),
            okText: this.$t("Deposit"),
            cancelText: this.$t("Cancel")
          };
        } else {
          displayErrors(err);
        }
      } finally {
        this.isTrading = false;
      }
    },
    handleCancel() {
      this.visibleReview = false;
      this.isTrading = false;
    },
    handleTradeSuccess() {
      this.visibleTrade = false;
      this.$emit("trade-success");
    },
    errorModalOnOk() {
      this.modalResultProps.visible = false;
      this.visibleTrade = false;
      if (!this.isEnabled && !this.isTrading) {
        this.$emit("toggle-account-status", true);
      }
      if (this.isWithdrawing) {
        // redirect to withdraw page
        this.$router.push({ name: "trade.withdraw.crypto" });
      }
    },
    confirmOnOk() {
      this.confirmModalProps.visible = false;
      window.open(
        this.urlDeposit.replace(/{{SYMBOL}}/, this.token),
        "LegendSDK_Crypto_Despsit"
      );
    },
    confirmOnCancel() {
      this.confirmModalProps.visible = false;
    },
    async handleWithdraw() {
      try {
        const { quantity, token } = this;
        const { message } = await addSettlement({
          amount_pay_to: quantity,
          asset_pay_to: token
        });
        this.modalResultProps = {
          visible: true,
          title: message,
          message: this.$t("Submitted Content"),
          type: "success"
        };
        this.isWithdrawing = true;
      } catch (err) {
        let { message, response } = err;
        if (response) {
          message = response.data.message;
        }
        this.modalResultProps = {
          visible: true,
          title: "Withdraw failed!",
          message: message,
          type: "error"
        };
        this.isWithdrawing = false;
      }
    },
    handleWithdrawLater() {
      this.handleTradeSuccess();
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.cardRfq {
  .link {
    margin: 0.5rem 0 -0.5rem;
    text-align: right;
  }
  :global {
    .ant-card-body {
      align-items: stretch;
      display: flex;
      flex-flow: column nowrap;
      & > div,
      & > span {
        margin-bottom: 1rem;
      }
    }

    .ant-radio-group {
      display: flex;
      text-align: center;
      .ant-radio-button-wrapper {
        flex: 1;
      }
    }

    .ant-input-group-wrapper.failed {
      .ant-input-group-addon,
      .ant-input {
        border-color: #e3342f;
      }
    }

    .ant-card-actions > li {
      line-height: 2rem;
      margin: 6px 0;
      overflow: hidden;
      padding-right: 14px;
      text-align: right;
      text-overflow: ellipsis;
    }
  }
}

.itemReview,
.itemTrade {
  @include padding-bottom(0.3rem);
  @include padding-left(1rem);
  @include padding-right(1rem);
  @include padding-top(0.3rem);

  // border-bottom: 1px solid var(--primary-5);
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: 0 none;
  }
  .countdown {
    flex: 1;
    text-align: center;
  }
}

.modalReview,
.modalTrade {
  top: 7.75rem;

  :global {
    .ant-modal-header {
      border-bottom: 0 none;
    }
    .ant-modal-body {
      padding-bottom: 0;
      padding-top: 0;
    }
    .ant-modal-footer {
      border-top: 0 none;
      text-align: center;
      .withdraw-msg {
        display: inline-block;
        color: var(--secondary);
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        padding: 0 0px 24px 28px;
      }
    }
    .ant-list {
      border-bottom: 1px solid var(--primary-5);
      border-top: 1px solid var(--primary-5);
    }
  }
}

.modalTitle {
  @include rfs(1.25rem);

  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-align: center;

  .textSuccess {
    color: var(--success);
  }
  .textInfo {
    color: var(--info);
  }
}
</style>
