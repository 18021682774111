const tmplPairs = {
  USDTUSD: "USDT / USD",
  BTCUSD: "BTC / USD",
  ETHUSD: "ETH / USD",
  XRPUSD: "XRP / USD",
  LTCUSD: "LTC / USD",
  BTCEUR: "BTC / EUR",
  ETHEUR: "ETH / EUR",
  XRPEUR: "XRP / EUR",
  LTCEUR: "LTC / EUR",
  USDTEUR: "USDT / EUR",
  BTCJPY: "BTC / JPY",
  BTCGBP: "BTC / GBP",
  ETHGBP: "ETH / GBP",
  XRPGBP: "XRP / GBP",
  LTCGBP: "LTC / GBP",
  BTCAUD: "BTC / AUD",
  ETHAUD: "ETH / AUD",
  XRPAUD: "XRP / AUD",
  LTCAUD: "LTC / AUD",
  USDTAUD: "USDT / AUD",
  BTCSGD: "BTC / SGD",
  ETHSGD: "ETH / SGD",
  XRPSGD: "XRP / SGD",
  LTCSGD: "LTC / SGD",
  USDTSGD: "USDT / SGD",
  BCHSGD: "BCH / SGD",
  BTCMXN: "BTC / MXN",
  XRPMXN: "XRP / MXN",
  BTCUSDT: "BTC / USDT",
  ETHUSDT: "ETH / USDT",
  XRPUSDT: "XRP / USDT",
  LTCUSDT: "LTC / USDT"
};

const tmplRfq = {
  created: "@now('yyyy-MM-dd')T@now('HH:mm:ss.SS')000Z",
  valid_until: "@now('yyyy-MM-dd')T@now('HH:mm:ss.SS')000Z",
  rfq_id: "@guid()",
  client_rfq_id: "@guid()",
  "quantity|1-999999.0-5": 1,
  "price|1-99999.0-2": 100,
  // "price": 2222,
  side: "@pick(['sell', 'buy'])",
  pair: "@pick(['BTCUSD', 'ETHUSD', 'XRPUSD', 'LTCUSD', 'USDTUSD'])"
};

const tmplTrade = {
  created: "@now('yyyy-MM-dd')T@now('HH:mm:ss.SS')000Z",
  trade_id: "@guid()",
  rfq_id: "@guid()",
  "quantity|1-999999.0-5": 1,
  "price|1-99999.0-2": 100,
  side: "@pick(['sell', 'buy'])",
  pair: "@pick(['BTCUSD', 'ETHUSD', 'XRPUSD', 'LTCUSD', 'USDTUSD'])"
};

const tmplTradeError = {
  message: "Minimum trade value is $2,000 or equivalent",
  errors: { trade: "Minimum trade value is $2,000 or equivalent" },
  error_code: "trade_size_too_small"
};

const tmplTradeHistory = {
  "array|50-1000": [
    {
      trade_id: "@guid()",
      rfq_id: "@guid()",
      "quantity|1-9.0-5": 1,
      "price|1-9.0-2": 100,
      side: "@pick(['sell', 'buy'])",
      pair: "@pick(['BTCUSD', 'ETHUSD', 'XRPUSD', 'LTCUSD', 'USDTUSD'])",
      created_at: "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z",
      user: "@email"
    }
  ]
};

const tmplSettleHistory = {
  "array|50-1000": [
    {
      settlement_id: "@guid()",
      asset_received: "",
      "amount_pay_to|1-999999.0-5": 1,
      asset_pay_to: "@pick(['USD', 'EUR', 'JPY'])",
      settled: "NO",
      updated_at: "@datetime()",
      amount_received: "0.00000000",
      settlement_status: "pending",
      cancel_note: null,
      settlement_type: "fiat",
      destination_type: "kyc_individual_banking"
    }
  ]
};

const tmplBalance = {
  crypto_exchange: {
    USDT: {
      "amount|1-99.0-2": 100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    BTC: {
      "amount|1-999.0-2": 100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    ETH: {
      "amount|1-99.0-2": 100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    }
  },
  crypto: {
    BTC: {
      amount: 0,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    ETH: {
      "amount|1-99.0-2": 100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    LTC: {
      "amount|1-99.0-2": -100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    USDT: {
      amount: -100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    }
  },
  fiat: {
    USD: {
      "amount|1-99999.0-4": 100,
      "updated_at|": "@datetime('yyyy-MM-dd')T@datetime('HH:mm:ss.SS')000Z"
    },
    EUR: {
      "amount|1-99999.0-4": 100,
      "updated_at|": null
    },
    JPY: {
      "amount|1-99999.0-4": 100,
      "updated_at|": null
    }
  }
};

const tmplBankList = {
  account_name: "sdf",
  account_address: "sdf",
  bank_name: "sdf",
  bank_address: "sdf",
  bank_country: "Algeria",
  account_number: "dsfds233333",
  routing_number: null,
  bank_swift: "sdf",
  bank_file_original_name: "Screenshot 2020-05-13 21.11.40.png",
  created_at: "@datetime()",
  updated_at: "@datetime()"
};

const tmplReadGeneralInfo = {
  account_email: "rowon96692@jupiterm.com",
  entity_legal_name: "Test1",
  entity_legal_identifier: "Test1",
  country: "China",
  business_address: "LA ZOO",
  city: "LA",
  state: "CA",
  zipcode: "123456",
  date_of_formation: "2020-04-13",
  phone_country_code: "11",
  phone_country_code_short: "CN",
  phone_number: "18551855717",
  company_website: "http://test.com",
  created_at: "2020-05-11 03:13:20",
  updated_at: "2020-05-11 03:13:20"
};

const tmplReadRepresentatives = {
  first_name: "Institution_1",
  middle_name: "123123",
  last_name: "CF489324",
  title: "CFO",
  email: "afdsfd@fdsfdjo.com",
  country: "China",
  residential_address: "243cc",
  city: "ShangHai_1",
  state: "ShangHai",
  zipcode: "446699",
  birthdate: "1999-02-03",
  phone_country_code: "+13",
  phone_country_code_short: "CN",
  phone_number: "18551855717",
  ssn: "dsfuiosf3432890new2",
  id_file_original_name: "学生登记表--电子版表格2.pdf",
  selfie_file_original_name: "Tall 1.jpg",
  id_type: "dl",
  created_at: "2020-05-10 23:50:16",
  updated_at: "2020-05-10 23:50:16"
};

const tmplReadBeneficialOwners = {
  limit_individual: "Yes",
  first_name: "Institution_1",
  middle_name: "nullable|string",
  last_name: "CF489324",
  title: "CFO",
  country: "China",
  residential_address: "243cc",
  city: "ShangHai_1",
  state: "ShangHai",
  zipcode: "446699",
  birthdate: "1999-02-03",
  phone_country_code: "+13",
  phone_country_code_short: "CN",
  phone_number: "18551855717",
  ssn: "dsfuiosf3432890new2",
  id_file_original_name: "学生登记表--电子版表格2.pdf",
  selfie_file_original_name: "Tall 1.jpg",
  proof_of_residence_file_original_name: "move_2.jpg",
  id_type: "dl",
  created_at: "2020-05-11 04:31:15",
  updated_at: "2020-05-11 04:31:15"
};

const tmplReadDocuments = {
  certification_file_original_name: "move_2.jpg",
  agreement_file_original_name: "13610_10$bill.jpg",
  organization_file_original_name: "13610_short(narrow).png",
  address_file_original_name: "image copy1.png",
  regulated_financial_institution: "Yes",
  policy_file_original_name: "Screen Shot 2020-05-10 at 10.11.11 PM.png",
  created_at: "2020-05-11 05:27:44",
  updated_at: "2020-05-11 06:16:34"
};

const tmplCompanyBankingInfo = {
  account_name: "acd_name",
  account_address: "account_address213",
  bank_name: "BOA1",
  bank_address: "bank_address123",
  bank_country: "China",
  account_number: "123",
  routing_number: "",
  bank_swift: "bank_swift",
  bank_file_original_name: "Screen Shot 2020-04-01 at 17.00.51.png",
  created_at: "2020-05-11 06:28:10",
  updated_at: "2020-05-11 06:28:10"
};

const tmplCompanyQuestionnaire = {
  connect_with_sanctioned_countries: "Yes",
  is_officer: "Yes",
  related_officer: "No",
  purpose: "I don't have any purpose",
  nature_of_business: "Nature of Business",
  source_of_funds: "Funds?",
  comments: "No commentsa",
  created_at: "2020-05-11 06:43:39",
  updated_at: "2020-05-11 06:43:39"
};

const tmplReadIdentification = {
  id_no: "@id",
  id_file_original_name: "@word().png",
  selfie_file_original_name: "@word().png",
  proof_of_residence_file_original_name: "@word().png",
  created_at: "@datetime",
  updated_at: "@datetime",
  id_type: "@pick(['passport', 'dl'])",
  id_back_file_original_name: "@word().png",
  id_file:
    "aW5kaXZpZHVhbC82M2I2NmE0NzliMjgzODAyMTMxNjkwMWI0MGJkMGY3ZS9pZC84OGExOWEzOTcwNTU5ZmM4MjAwZDJkZGI0NjE5MDljNS5wbmc=",
  selfie_file:
    "aW5kaXZpZHVhbC82M2I2NmE0NzliMjgzODAyMTMxNjkwMWI0MGJkMGY3ZS9pZC82ZGQ3Y2E2MDI0MTM0OTA5ODlhYjFjMTc4MDIxYTFkYy5wbmc=",
  proof_of_residence_file:
    "aW5kaXZpZHVhbC82M2I2NmE0NzliMjgzODAyMTMxNjkwMWI0MGJkMGY3ZS9pZC80MjlmN2Y1YmMyZjA3NjYzY2VlNWM3MWZmMmM2ODgwYS5wbmc=",
  id_back_file:
    "aW5kaXZpZHVhbC82M2I2NmE0NzliMjgzOaXZpZHVhbC82M2I2NmE0NzliMjgzODAyMTMxNjkwMWI0MGJkMGY3ZS9pZC84OGExOWEzOTcwNTU5ZmM4MjAwZDJkZGI0NjE5MDljNS5wbmc"
};

const tmplGetAccountCustomSupport = {
  cs: "support@legendtrading.com"
};

const tmplUpdateBankInfo = {
  message: "succeeded"
};

const tmplGetCurrencies = [
  { currency: "USD", currency_name: "US Dollar" },
  { currency: "EUR", currency_name: "Euro" },
  { currency: "AUD", currency_name: "Australian Dollar" },
  { currency: "SGD", currency_name: "Singapore Dollar" },
  { currency: "GBP", currency_name: "British pound sterling" },
  { currency: "CHF", currency_name: "Swiss Franc " },
  { currency: "JPY", currency_name: "Japanese Yen" },
  { currency: "MXN", currency_name: "Mexican Peso " }
];

const tmplGetBankInfo = {
  currency: "USD",
  currency_name: "US Dollar",
  transfer_type: "wire transfer",
  parameters: {
    "Account Name": "LEGEND TRADING INC.",
    "Account Address":
      "960 SAN ANTONIO RD, STE 200, PALO ALTO CA 94303, United State",
    "Bank Name": "Silvergate Bank",
    "Bank Address":
      "4250 Executive Square, Suite 300, La Jolla, CA 92037, United States",
    "Bank Country": "United States",
    "Account Number": "5090021758",
    "Routing Number": "322286803",
    "Bank SWIFT": "SIVGUS66",
    "Sort Code": "040541",
    IBAN: "GB29 BCPY 040541 00003326",
    BIC: "BCPYGB22",
    "SWIFT BIC": "111"
  }
};

const tmplGetWirecode = { reference_code: "BTLYJNE" };

export {
  tmplPairs,
  tmplRfq,
  tmplTrade,
  tmplTradeError,
  tmplTradeHistory,
  tmplSettleHistory,
  tmplBalance,
  tmplBankList,
  tmplReadGeneralInfo,
  tmplReadRepresentatives,
  tmplReadBeneficialOwners,
  tmplReadDocuments,
  tmplCompanyBankingInfo,
  tmplCompanyQuestionnaire,
  tmplReadIdentification,
  tmplGetAccountCustomSupport,
  tmplUpdateBankInfo,
  tmplGetCurrencies,
  tmplGetBankInfo,
  tmplGetWirecode
};
