import Vue from "vue";
import Router from "vue-router";
// import Home from "../views/Home.vue";
import Trade from "../views/trade/Index.vue";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/home",
    //   name: "home",
    //   component: Home
    // },
    {
      path: "/kyc",
      name: "kyc.index",
      component: () =>
        import(/* webpackChunkName: "kyc" */ "../views/kyc/Index.vue")
    },
    {
      path: "/terms",
      name: "kyc.terms",
      component: () =>
        import(/* webpackChunkName: "kyc" */ "../views/kyc/Terms.vue")
    },
    {
      path: "/applyType",
      name: "kyc.applyType",
      component: () =>
        import(/* webpackChunkName: "kyc" */ "../views/kyc/ApplyType.vue")
    },
    {
      path: "/individual/index",
      name: "kyc.individual.index",
      component: () =>
        import(
          /* webpackChunkName: "kyc" */ "../views/kyc/Individual/Index.vue"
        )
    },
    {
      path: "/institution/index",
      name: "kyc.institution.index",
      component: () =>
        import(
          /* webpackChunkName: "kyc" */ "../views/kyc/Institution/Index.vue"
        )
    },
    {
      path: "/applyDone",
      name: "kyc.applyDone",
      component: () =>
        import(/* webpackChunkName: "kyc" */ "../views/kyc/ApplyDone.vue")
    },
    {
      path: "/trade",
      name: "trade.index",
      component: Trade
    },
    {
      path: "/history",
      name: "trade.history",
      meta: { requireOtcAccount: true },
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/trade/History.vue")
    },
    {
      path: "/deposit",
      name: "trade.deposit",
      meta: { requireOtcAccount: true },
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/trade/Deposit.vue")
    },
    {
      path: "/withdraw/fiat/:activeKey?",
      name: "trade.withdraw.fiat",
      meta: { requireOtcAccount: true },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "trade" */ "../views/trade/WithdrawFiat.vue"
        )
    },
    {
      path: "/withdraw/crypto/:activeKey?",
      name: "trade.withdraw.crypto",
      meta: { requireOtcAccount: true },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "trade" */ "../views/trade/WithdrawCrypto.vue"
        )
    },
    {
      path: "/deposit/guide",
      name: "trade.deposit.guide",
      meta: { requireOtcAccount: true },
      component: () =>
        import(
          /* webpackChunkName: "trade" */ "../views/trade/DepositGuide.vue"
        )
    },
    {
      path: "/wallet/:activeKey?",
      name: "trade.wallet",
      props: true,
      meta: { requireOtcAccount: true },
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/trade/Wallet.vue")
    },
    {
      path: "/maintain",
      name: "maintain",
      component: () =>
        import(/* webpackChunkName: "trade" */ "../views/Maintain.vue")
    },
    {
      path: "*",
      redirect: "/trade"
    }
  ]
});

// trigger init route
router.push("/trade");

router.beforeEach((to, from, next) => {
  const { isAccountEnabled, isAccountPending } = store.getters;
  const { maintenance } = store.state;
  const { name, meta } = to;
  if (maintenance) {
    next({ name: "maintain" });
  } else if (!isAccountEnabled && meta.requireOtcAccount) {
    // require enable OTC account first
    next({ name: "trade.index" });
  } else if (isAccountPending && name.startsWith("kyc.")) {
    // OTC account pending
    next({ name: "trade.index" });
  } else if (isAccountEnabled && name.startsWith("kyc.")) {
    // OTC account enabled
    next({ name: "trade.index" });
  } else {
    next();
  }
});

export default router;
