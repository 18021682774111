// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PoweredBy_powered_2z5xm{-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:var(--gray);display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.PoweredBy_powered_2z5xm:hover{color:var(--primary)}.PoweredBy_logo_381uj{height:1rem;margin-left:.5rem;width:9.04rem}", ""]);
// Exports
exports.locals = {
	"powered": "PoweredBy_powered_2z5xm",
	"logo": "PoweredBy_logo_381uj"
};
module.exports = exports;
