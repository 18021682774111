<template>
  <a-modal
    :title="$t('Disclaimer')"
    v-model="isModalVisible"
    :cancelText="$t('Cancel')"
    :okText="$t('Continue')"
    :okButtonProps="{ props: { disabled: !agreeAll } }"
    :getContainer="() => $root.$el"
    :closable="false"
    @ok="handleOk"
  >
    <p v-t="'disclaimer_messages[0]'"></p>
    <p v-if="!noLegend" v-t="'disclaimer_messages[1]'"></p>
    <i18n path="disclaimer_messages[2]" tag="p" :class="$style.contact">
      <br />
      <a :href="`mailto:${email}`">{{ email }}</a>
    </i18n>
    <p>{{ $t("disclaimer_messages[3]", [appId]) }}</p>

    <a-row type="flex" justify="start">
      <a-col :span="18">
        <a-checkbox v-model="agreeDisclaimer">{{
          $t("disclaimer_messages[4]")
        }}</a-checkbox>
      </a-col>
      <a-col :span="18">
        <a-checkbox v-model="agreeTermsAndPolicy">
          <i18n path="disclaimer_messages[5]">
            <br />
            <a
              :class="$style.indent"
              target="_blank"
              href="https://legendtrading.com/terms"
              v-t="'Terms and Conditions'"
            ></a>
            <a
              target="_blank"
              href="https://legendtrading.com/policy"
              v-t="'Privacy Policy'"
            ></a>
          </i18n>
        </a-checkbox>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import { Checkbox, Col, Divider, Modal, Row } from "ant-design-vue";

export default {
  name: "ModalDisclaimer",
  components: {
    [Checkbox.name]: Checkbox,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Modal.name]: Modal,
    [Row.name]: Row
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      agreeDisclaimer: false,
      agreeTermsAndPolicy: false
    };
  },
  computed: {
    ...mapState({
      appId: state => state.clientInfo.appId,
      email: state => state.clientInfo.email,
      noLegend: state => state.clientInfo.noLegend
    }),
    isModalVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    },
    agreeAll() {
      return this.agreeDisclaimer && this.agreeTermsAndPolicy;
    }
  },
  methods: {
    handleOk() {
      this.isModalVisible = false;
      this.$router.push({ name: "kyc.applyType" });
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
.contact {
  margin-bottom: 0.5em;
  a {
    line-height: 3;
  }
}
.indent {
  margin-left: 1.5rem;
}
</style>
