<template>
  <section :class="$style.account">
    <a-card :class="$style.status" :bordered="false">
      <div :class="$style.switch">
        <span
          v-if="!showRipple && accountEmail"
          :title="accountEmail"
          :class="$style.email"
        >
          <a-icon type="user" />
          {{ accountEmail }}
        </span>
        <span v-else>{{ $t("OTC Account Status") }}</span>
        <div :class="{ [$style.ripple]: showRipple }">
          <a-switch
            v-bind="propsSwitch"
            :size="uiSize"
            @change="$emit('toggle-account-status', $event)"
          />
        </div>
      </div>
    </a-card>

    <balance-card ref="balance" v-if="isEnabled" />

    <a-alert :class="$style.alert" v-else showIcon :type="alertType">
      <logo-legend v-if="!noLegend" slot="icon" :class="$style.logo" />
      <template slot="message" v-if="isPending">
        <p>
          <strong>{{ $t("account_messages[1]") }}</strong>
        </p>
        <i18n path="account_messages[2]" tag="p">
          <template v-slot:day>
            <span :class="$style.highlight">2</span>
          </template>
        </i18n>
        <p :class="$style.highlight">{{ $t("account_messages[3]") }}</p>
        <p :class="$style.highlight">
          <strong> {{ $t("account_messages[4]") }} </strong>
        </p>
        <p>
          <a href="mailto:onboarding@legendtrading.com">
            onboarding@legendtrading.com
          </a>
        </p>
      </template>
      <template slot="message" v-else>
        <strong>{{ $t("account_messages[0]") }}</strong>
      </template>
    </a-alert>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Alert, Button, Card, Icon, Switch } from "ant-design-vue";
import BalanceCard from "@/components/BalanceCard.vue";
import LogoLegend from "@/assets/img/logo.svg?inline";

export default {
  name: "AccountStatus",
  components: {
    [Alert.name]: Alert,
    [Button.name]: Button,
    [Card.name]: Card,
    [Icon.name]: Icon,
    [Switch.name]: Switch,
    BalanceCard,
    LogoLegend
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      accountEmail: state => state.accountEmail,
      noLegend: state => state.clientInfo.noLegend,
      uiSize: state => state.clientInfo.uiSize
    }),
    ...mapGetters({
      isEnabled: "isAccountEnabled",
      isPending: "isAccountPending"
    }),
    showRipple() {
      const { isEnabled, isPending } = this;
      return !isEnabled && !isPending;
    },
    propsSwitch() {
      const { isEnabled, isPending } = this;
      const common = {
        checked: isEnabled
      };
      const pending = {
        disabled: isPending,
        unCheckedChildren: this.$t("PENDING")
      };
      return isPending ? { ...common, ...pending } : common;
    },
    alertType() {
      return this.isPending ? "success" : "info";
    },
    alertMessage() {
      return this.isPending
        ? this.$t("account_messages[1]")
        : this.$t("account_messages[0]");
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

$ripple-color: #000;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0rem rgba($ripple-color, 0.2),
      0 0 0 0.4rem rgba($ripple-color, 0.2), 0 0 0 1rem rgba($ripple-color, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0.4rem rgba($ripple-color, 0.2),
      0 0 0 1rem rgba($ripple-color, 0.2), 0 0 0 1.6rem rgba($ripple-color, 0);
  }
}

.ripple {
  position: relative;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  border-radius: 50%;
  content: "";
  display: block;
  margin: -1.5rem 0 0 -1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 50%;
  left: 50%;
  pointer-events: none;
  animation: ripple 1s linear infinite;
}

.account {
  .status {
    @include margin-bottom(1rem);
    position: relative;
    z-index: 1;

    .switch {
      display: flex;
      justify-content: space-between;
    }

    :global(.ant-card-body) {
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }

  .alert {
    border: 0 none;
  }

  .status,
  .alert {
    box-shadow: 0 0 20px rgba(235, 240, 245, 0.5);
  }
  .email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    :global(.anticon) {
      margin-right: 0.5em;
    }
  }
}
.logo {
  display: block;
  height: 1rem;
  margin: -1px;
  width: 1rem;
}
.highlight {
  color: var(--red);
}
</style>
