<template>
  <section :class="$style.home">
    <top-nav><slot name="title" slot="title"/></top-nav>
    <a-row v-bind="propRow">
      <a-col :span="12">
        <trade-rfq
          :class="$style.rfq"
          @trade-success="handleTrade"
          @toggle-account-status="handleAccount"
        />
      </a-col>
      <a-col :span="12">
        <account-status
          ref="account"
          :class="$style.account"
          @toggle-account-status="handleAccount"
        />
      </a-col>
    </a-row>
    <faq-why />

    <modal-disclaimer :visible.sync="isModalVisible" />
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Col, Row } from "ant-design-vue";
import TopNav from "@/components/TopNav.vue";
import TradeRfq from "@/components/TradeRfq.vue";
import AccountStatus from "@/components/AccountStatus.vue";
import FaqWhy from "@/components/FaqWhy.vue";
import ModalDisclaimer from "@/components/ModalDisclaimer.vue";

export default {
  name: "Trade",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    TopNav,
    TradeRfq,
    AccountStatus,
    FaqWhy,
    ModalDisclaimer
  },
  data() {
    return {
      propRow: {
        type: "flex",
        justify: "space-between",
        gutter: { xs: 8, sm: 16, md: 24, lg: 32 }
      },
      isModalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      isEnabled: "isAccountEnabled"
    }),
    ...mapState({
      noLegend: state => state.clientInfo.noLegend
    })
  },
  methods: {
    ...mapActions(["toggleAccountStatus"]),
    handleAccount(enabled) {
      // this.toggleAccountStatus(enabled);
      if (enabled) {
        if (this.noLegend) {
          this.$router.push({ name: "kyc.applyType" });
        } else {
          this.isModalVisible = true;
        }
      }
    },
    handleTrade() {
      const { balance } = this.$refs.account.$refs;
      if (balance) {
        balance.fetchData();
      }
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => (vm.isModalVisible = false));
  },
  beforeRouteLeave(to, from, next) {
    this.isModalVisible = false;
    next();
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.home {
  display: flex;
  flex-flow: column nowrap;
}
.rfq {
  box-shadow: 0 0 20px rgba(235, 240, 245, 0.5);
}
</style>
