// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalDisclaimer_contact_3xTi5{margin-bottom:.5em}.ModalDisclaimer_contact_3xTi5 a{line-height:3}.ModalDisclaimer_indent_ZRkQM{margin-left:1.5rem}", ""]);
// Exports
exports.locals = {
	"contact": "ModalDisclaimer_contact_3xTi5",
	"indent": "ModalDisclaimer_indent_ZRkQM"
};
module.exports = exports;
