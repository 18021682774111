<template>
  <a-modal
    :closable="false"
    :visible="modalResultProps.visible"
    :getContainer="() => $root.$el"
    :footer="null"
    :zIndex="1100"
  >
    <div :class="$style.modal">
      <div :class="$style.title">
        <a-icon
          :class="$style.success"
          type="check-circle"
          v-if="modalResultProps.type === 'success'"
        />
        <a-icon
          :class="$style.error"
          type="close-circle"
          v-if="modalResultProps.type === 'error'"
        />
        {{ modalResultProps.title }}
      </div>
      <div :class="$style.content">
        {{ modalResultProps.message }}
      </div>
      <div :class="$style.footer">
        <a-button type="primary" @click="$emit('click-ok')">
          {{ $t("OK") }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { Modal, Button, Icon } from "ant-design-vue";

export default {
  name: "ResultModal",
  props: {
    modalResultProps: [Object]
  },
  components: {
    [Modal.name]: Modal,
    [Button.name]: Button,
    [Icon.name]: Icon
  }
};
</script>

<style lang="less" module>
.modal {
  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .success {
      font-size: 22px;
      margin-right: 16px;
      color: #52c41a;
    }
    .error {
      font-size: 22px;
      margin-right: 16px;
      color: #f5222d;
    }
  }
  .content {
    margin-left: 40px;
    font-size: 14px;
  }
  .footer {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
