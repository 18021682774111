<template>
  <a-collapse>
    <a-collapse-panel
      v-for="(item, index) in $t('faq_messages')"
      :key="index"
      :header="`${index + 1}. ${item[0]}`"
    >
      <section class="clearfix">
        <template v-for="(message, i) in getLocaleMessage(item)">
          <i18n
            v-if="message.includes('{email}')"
            tag="p"
            :path="`faq_messages[${index}][1][${i}]`"
            :key="`message-${index}-${i}`"
          >
            <template v-slot:email>
              <a :class="$style.email" :href="`mailto:${email}`">
                {{ email }}
              </a>
            </template>
          </i18n>
          <i18n
            v-else-if="message.includes('{table_fee}')"
            tag="div"
            :class="$style.fee"
            :path="`faq_messages[${index}][1][${i}]`"
            :key="`message-${index}-${i}`"
          >
            <template v-slot:table_fee>
              <table>
                <thead>
                  <tr>
                    <th>{{ $t("Trade Size") }}</th>
                    <th>{{ $t("Trading Fee") }}</th>
                    <th>{{ $t("Wire Fee") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in fee" :key="index">
                    <td v-for="(item, i) in row" :key="`${index}-${i}`">
                      {{ item }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </i18n>
          <i18n
            v-else-if="message.includes('{appId}')"
            tag="p"
            :path="`faq_messages[${index}][1][${i}]`"
            :key="`message-${index}-${i}`"
          >
            <template v-slot:appId>
              {{ appId }}
            </template>
          </i18n>
          <template v-else-if="message.startsWith('[:strong]')">
            <strong :key="`message-${index}-${i}`">{{
              message.replace(/^\[\:strong\]/, "")
            }}</strong>
            <br :key="`br-${index}-${i}`" />
          </template>
          <template v-else>
            {{ message }} <br :key="`br-${index}-${i}`" />
          </template>
        </template>
      </section>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Collapse } from "ant-design-vue";
import { getFees as getTradeFees } from "@/api";
import { getFees as getKycFees } from "@/api/kyc";

export default {
  name: "FaqContent",
  components: {
    [Collapse.name]: Collapse,
    [Collapse.Panel.name]: Collapse.Panel
  },
  data() {
    return {
      email: "support@legendtrading.com",
      fee: []
      // fee: [
      //   ["$500", "2.00%", "$30"],
      //   ["$2,000", "1.50%", "$30"],
      //   ["$5,000", "1.50%", "$30"],
      //   ["$10,000", "1.50%", "$30"],
      //   ["$50,000", "1.00%", "$0"],
      //   ["$100,000", "0.50%", "$0"],
      //   ["$500,000", "0.10%", "$0"],
      //   ["$1,000,000", "0.08%", "$0"]
      // ]
    };
  },
  computed: {
    ...mapState({
      appId: state => state.clientInfo.appId,
      token: state => state.token
    }),
    ...mapGetters(["isAccountEnabled"]),
    getFees() {
      return this.isAccountEnabled ? getTradeFees : getKycFees;
    }
  },
  watch: {
    token: {
      handler(val) {
        if (val) {
          this.fetchFees();
        }
      },
      immediate: true
    }
  },
  methods: {
    getLocaleMessage([, common, custom = {}]) {
      return custom[this.appId] || common;
    },
    async fetchFees() {
      const res = await this.getFees();
      if (res) {
        this.fee = res;
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
.fee {
  float: right;
  margin-left: 0.5rem;
  table {
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid var(--gray);
    padding: 0.25rem 0.5rem;
  }
  td {
    text-align: right;
  }
}
.email {
  border-bottom: 1px solid currentColor;
  font-weight: 600;
}
</style>
