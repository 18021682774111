<template>
  <section v-if="!clientInfo.noLegend" :class="$style.powered">
    {{ $t("Powered by") }}
    <logo-gold :class="$style.logo" />
  </section>
</template>

<script>
import { mapState } from "vuex";
import LogoGold from "@/assets/img/logo-gold.svg?inline";

export default {
  name: "TradeRfq",
  components: {
    LogoGold
  },
  computed: {
    ...mapState(["clientInfo"])
  }
};
</script>

<style lang="scss" module>
.powered {
  align-items: center;
  color: var(--gray);
  display: inline-flex;
  user-select: none;
  &:hover {
    color: var(--primary);
  }
}
.logo {
  height: 1rem;
  margin-left: 0.5rem;
  width: 9.04rem;
}
</style>
