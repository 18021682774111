import { DateTime } from "luxon";
import { Notification } from "ant-design-vue";
import { countryList } from "./countryList";
import { highRiskCountryList } from "./highRiskCountryList";

const logSuccess = res => {
  /* eslint-disable-next-line */
  console.log(
    "%c Request Success: %s",
    "color: #4CAF50; font-weight: bold",
    res.config.url,
    res
  );
  return res;
};

const logError = err => {
  /* eslint-disable-next-line */
  console.log("%c Request Error:", "color: #EC6060; font-weight: bold", err);
  return Promise.reject(err);
};

const displayErrors = err => {
  if (err.response) {
    const { message, errors } = err.response.data;
    if (typeof errors === "object") {
      Object.entries(errors).forEach(([key, msgs]) => {
        let description = "";
        if (Array.isArray(msgs)) {
          description = h => msgs.map(n => [n, h("br")]);
        } else {
          description = msgs;
        }
        Notification.error({
          // capitalize key
          message: key.charAt(0).toLocaleUpperCase() + key.slice(1),
          description,
          placement: "bottomRight"
        });
      });
    } else {
      Notification.error({
        message: "Error",
        description: message,
        placement: "bottomRight"
      });
    }
  } else {
    Notification.error({
      message: "Error",
      description: err.message,
      placement: "bottomRight"
    });
  }
};

const sizeList = ["large", "default", "small"];

const dtNow = DateTime.local();
const dtLastMonth = dtNow.minus({ months: 1 });

const dateConfig = {
  moFormat: "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]",
  dtFormat: "yyyy-MM-dd'T'HH:mm:ss.u'000Z'",
  dtNow,
  dtLastMonth,
  dtDateOptions: [
    [dtNow.startOf("day"), ""], // today
    [dtNow.minus({ days: 1 }).startOf("day"), ""], // yesterday
    [dtNow.minus({ days: 7 }).startOf("day"), ""], // last 7 days
    [dtNow.minus({ days: 30 }).startOf("day"), ""], // last 30 days
    [dtNow.startOf("month"), ""], // this month
    [dtLastMonth.startOf("month"), dtLastMonth.endOf("month")], // last month
    [DateTime.fromMillis(0), ""] // all
  ],
  dateOptions: [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "All"
  ]
};

const getStartDate = () => {
  const { dtDateOptions, dtFormat } = dateConfig;
  return dtDateOptions[0][0].toUTC().toFormat(dtFormat);
};

const apiConfig = {
  store: {
    token: "",
    envConfig: {
      development: "devapi",
      sandbox: "sandboxapi",
      production: "api"
    },
    domain: "legendtrading.com",
    env: process.env.NODE_ENV
  },
  reHostname: /^localhost|127\.0\.0\.1|::1$/i,
  get env() {
    return this.store.env;
  },
  set env(val) {
    this.store.env = val;
  },
  get token() {
    return this.store.token;
  },
  set token(val) {
    this.store.token = val;
  },
  get remote() {
    return this.store.envConfig.development;
  },
  set remote(val) {
    if (val) {
      this.store.envConfig.development = val;
    }
  },
  get baseUrl() {
    const { envConfig, domain } = this.store;
    return `https://${envConfig[this.env]}.${domain}`;
  },
  get baseUrlTrade() {
    return `${this.baseUrl}/ex/`;
  },
  get baseUrlKyc() {
    return `${this.baseUrl}/kyc/`;
  },
  get baseUrlPlaid() {
    return `${this.baseUrl}/plaid/`;
  }
};

const cryptoConfig = {
  order: ["BTC", "USDT", "ETH", "XRP", "LTC", "BCH", "EOS", "ETC"],
  name: {
    BTC: "Bitcoin",
    ETH: "Ethereum",
    USDT: "Tether",
    BCH: "Bitcoin Cash",
    LTC: "Litecoin",
    XRP: "Ripple"
  },
  img: {
    BCH: require("@/assets/img/crypto/bch.png"),
    BTC: require("@/assets/img/crypto/btc.png"),
    EOS: require("@/assets/img/crypto/eos.png"),
    ETC: require("@/assets/img/crypto/etc.png"),
    ETH: require("@/assets/img/crypto/eth.png"),
    LTC: require("@/assets/img/crypto/ltc.png"),
    USDT: require("@/assets/img/crypto/usdt.png"),
    XRP: require("@/assets/img/crypto/xrp.png")
  }
};

const getCountryList = () => {
  let countryArr = [];

  countryList?.forEach(item => {
    const lowRiskArr = item?.lists?.filter(
      lowRiskItem =>
        !highRiskCountryList.some(
          highRiskItem => lowRiskItem.ISO2 === highRiskItem.ISO2
        )
    );

    countryArr.push({
      letter: item.letter,
      lists: lowRiskArr
    });
  });

  return countryArr;
};

const injectGTM = (
  package_version = "",
  id = "GTM-MCX3QB4",
  layer = "dataLayerLegend"
) =>
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ package_version });
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", layer, id);

export {
  logSuccess,
  logError,
  displayErrors,
  sizeList,
  dateConfig,
  getStartDate,
  apiConfig,
  cryptoConfig,
  getCountryList,
  injectGTM
};

export default [logSuccess, logError];
