var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('a-card',{class:_vm.$style.cardRfq,attrs:{"bordered":false}},[_c('a-select',{directives:[{name:"page-guide",rawName:"v-page-guide",value:('debug.guide_messages[4]'),expression:"'debug.guide_messages[4]'"}],attrs:{"size":_vm.uiSize,"loading":_vm.isLoading,"dropdownMatchSelectWidth":false,"getPopupContainer":function () { return _vm.$root.$el; }},model:{value:(_vm.currentPair),callback:function ($$v) {_vm.currentPair=$$v},expression:"currentPair"}},_vm._l((_vm.pairs),function(pair,name){return _c('a-select-option',{key:name},[_vm._v(_vm._s(pair))])}),1),_c('a-radio-group',{attrs:{"size":_vm.uiSize,"buttonStyle":"solid"},model:{value:(_vm.side),callback:function ($$v) {_vm.side=$$v},expression:"side"}},[_c('a-radio-button',{attrs:{"value":"sell"}},[_vm._v(_vm._s(_vm.$t("SELL")))]),_c('a-radio-button',{attrs:{"value":"buy"}},[_vm._v(_vm._s(_vm.$t("BUY")))])],1),_c('ValidationProvider',{ref:"validation",attrs:{"mode":"eager","name":"quantity","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('a-input',{class:classes,attrs:{"value":_vm.quantity,"addonAfter":_vm.token,"size":_vm.uiSize},on:{"change":_vm.onQuantityChange,"pressEnter":function($event){return _vm.handleRfq()}}})]}}])}),_c('a-button',{attrs:{"block":"","size":_vm.uiSize,"type":"primary","loading":_vm.isTrading},on:{"click":function($event){return _vm.handleRfq()}}},[_vm._v(_vm._s(_vm.$t("REQUEST A QUOTE")))]),(_vm.isEnabled)?_c('section',{class:_vm.$style.link},[_c('router-link',{attrs:{"to":{ name: 'trade.history' }}},[_vm._v(" "+_vm._s(_vm.$t("Order History"))+" "),_c('a-icon',{attrs:{"type":"right"}})],1)],1):_vm._e(),_c('powered-by',{attrs:{"slot":"actions"},slot:"actions"})],1),_c('a-modal',{class:_vm.$style.modalReview,attrs:{"zIndex":1100,"width":456,"visible":_vm.visibleReview,"confirmLoading":_vm.isTrading,"closable":false,"okText":_vm.okText,"cancelText":_vm.$t('Cancel'),"getContainer":function () { return _vm.$root.$el; }},on:{"ok":_vm.handleTrade,"cancel":_vm.handleCancel}},[_c('h5',{class:_vm.$style.modalTitle,attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{class:_vm.$style.textInfo,attrs:{"type":"info-circle"}}),_vm._v(" "+_vm._s(_vm.$t("Review"))+" ")],1),_c('a-list',{attrs:{"size":"small","dataSource":_vm.listReview},scopedSlots:_vm._u([{key:"renderItem",fn:function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{class:_vm.$style.itemReview},[(key !== 'Expiry')?[_c('strong',[_vm._v(_vm._s(_vm.$t(key)))]),_c('span',[_vm._v(_vm._s(value))])]:[_c('strong'),_c('vac',{ref:"vacExpiry",class:_vm.$style.countdown,attrs:{"left-time":value,"auto-start":false},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var timeObj = ref.timeObj;
return [_c('span',[_vm._v(_vm._s(timeObj.ceil.s)+" "+_vm._s(_vm.$t("seconds")))])]}},{key:"finish",fn:function(){return [_c('span',{class:_vm.$style.countdown},[_vm._v(_vm._s(_vm.$t("expired")))])]},proxy:true}],null,true)})]],2)}}])})],1),_c('a-modal',{class:_vm.$style.modalTrade,attrs:{"zIndex":1100,"width":456,"visible":_vm.visibleTrade,"closable":false,"cancelButtonProps":{ hide: true },"getContainer":function () { return _vm.$root.$el; }},on:{"ok":_vm.handleTradeSuccess,"cancel":_vm.handleTradeSuccess}},[_c('h5',{class:_vm.$style.modalTitle,attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{class:_vm.$style.textSuccess,attrs:{"type":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t("Trade executed"))+" ")],1),_c('template',{slot:"footer"},[(_vm.isBuy)?[_c('span',{staticClass:"withdraw-msg"},[_vm._v(" "+_vm._s(_vm.$t("Withdraw MSG"))+" ")]),_c('a-button',{attrs:{"size":_vm.uiSize,"type":"primary"},on:{"click":_vm.handleWithdraw}},[_vm._v(" "+_vm._s(_vm.$t("Withdraw Amount"))+" "+_vm._s(_vm.quantity)+" "+_vm._s(_vm.token)+" ")]),_c('a-button',{attrs:{"size":_vm.uiSize,"type":"primary"},on:{"click":_vm.handleWithdrawLater}},[_vm._v(" "+_vm._s(_vm.$t("Withdraw Later"))+" ")])]:[_c('a-button',{attrs:{"size":_vm.uiSize,"type":"primary"},on:{"click":_vm.handleTradeSuccess}},[_vm._v(" "+_vm._s(_vm.$t("OK"))+" ")])]],2),_c('a-list',{attrs:{"size":"small","dataSource":_vm.listTrade},scopedSlots:_vm._u([{key:"renderItem",fn:function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{class:_vm.$style.itemTrade},[_c('strong',[_vm._v(_vm._s(_vm.$t(key)))]),_c('span',[_vm._v(_vm._s(value))])])}}])})],2),_c('result-modal',{attrs:{"modalResultProps":_vm.modalResultProps},on:{"click-ok":_vm.errorModalOnOk}}),_c('confirm-modal',{attrs:{"confirmModalProps":_vm.confirmModalProps},on:{"click-ok":_vm.confirmOnOk,"click-cancel":_vm.confirmOnCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }