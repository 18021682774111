// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResultModal_modal_REKqx .ResultModal_title_3R-EP{font-size:16px;color:rgba(0,0,0,.85);font-weight:500;margin-bottom:8px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ResultModal_modal_REKqx .ResultModal_title_3R-EP .ResultModal_success_3cbIZ{font-size:22px;margin-right:16px;color:#52c41a}.ResultModal_modal_REKqx .ResultModal_title_3R-EP .ResultModal_error_3gvWl{font-size:22px;margin-right:16px;color:#f5222d}.ResultModal_modal_REKqx .ResultModal_content_14VYj{margin-left:40px;font-size:14px}.ResultModal_modal_REKqx .ResultModal_footer_dK3y6{text-align:right;margin-top:10px}", ""]);
// Exports
exports.locals = {
	"modal": "ResultModal_modal_REKqx",
	"title": "ResultModal_title_3R-EP",
	"success": "ResultModal_success_3cbIZ",
	"error": "ResultModal_error_3gvWl",
	"content": "ResultModal_content_14VYj",
	"footer": "ResultModal_footer_dK3y6"
};
module.exports = exports;
