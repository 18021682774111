const highRiskCountryList = [
  {
    CHS: "阿富汗",
    ENG: "Afghanistan",
    EGNFullName: "the Islamic Republic of Afghanistan",
    ISO2: "AF"
  },
  {
    CHS: "阿尔巴尼亚",
    ENG: "Albania",
    EGNFullName: "the Republic of Albania",
    ISO2: "AL"
  },
  {
    CHS: "阿尔及利亚",
    ENG: "Algeria",
    EGNFullName: "the People's Democratic Republic of Algeria",
    ISO2: "DZ"
  },
  {
    CHS: "安哥拉",
    ENG: "Angola",
    EGNFullName: "the Republic of Angola",
    ISO2: "AO"
  },
  {
    CHS: "安提瓜和巴布达",
    ENG: "Antigua and Barbuda",
    EGNFullName: "",
    ISO2: "AG"
  },
  {
    CHS: "阿根廷",
    ENG: "Argentina",
    EGNFullName: "the Argentine Republic",
    ISO2: "AR"
  },
  {
    CHS: "亚美尼亚",
    ENG: "Armenia",
    EGNFullName: "the Republic of Armenia",
    ISO2: "AM"
  },
  {
    CHS: "阿鲁巴",
    ENG: "Aruba",
    EGNFullName: "",
    ISO2: "AW"
  },
  {
    CHS: "阿塞拜疆",
    ENG: "Azerbaijan",
    EGNFullName: "the Republic of Azerbaijan",
    ISO2: "AZ"
  },
  {
    CHS: "巴林",
    ENG: "Bahrain",
    EGNFullName: "the Kingdom of Bahrain",
    ISO2: "BH"
  },
  {
    CHS: "孟加拉国",
    ENG: "Bangladesh",
    EGNFullName: "the People's Republic of Bangladesh",
    ISO2: "BD"
  },
  {
    CHS: "巴巴多斯",
    ENG: "Barbados",
    EGNFullName: "",
    ISO2: "BB"
  },
  {
    CHS: "",
    ENG: "Belarus",
    EGNFullName: "",
    ISO2: "BY"
  },
  {
    CHS: "伯利兹",
    ENG: "Belize",
    EGNFullName: "",
    ISO2: "BZ"
  },
  {
    CHS: "贝宁",
    ENG: "Benin",
    EGNFullName: "the Republic of Benin",
    ISO2: "BJ"
  },
  {
    CHS: "玻利维亚",
    ENG: "Bolivia",
    EGNFullName: "the Republic of Bolivia",
    ISO2: "BO"
  },
  {
    CHS: "波黑",
    ENG: "Bosnia and Herzegovina",
    EGNFullName: "",
    ISO2: "BA"
  },
  {
    CHS: "博茨瓦纳",
    ENG: "Botswana",
    EGNFullName: "the Republic of Botswana",
    ISO2: "BW"
  },
  {
    CHS: "巴西",
    ENG: "Brazil",
    EGNFullName: "the Federative Republic of Brazil",
    ISO2: "BR"
  },
  {
    CHS: "布基纳法索",
    ENG: "Burkina Faso",
    EGNFullName: "Burkina Faso",
    ISO2: "BF"
  },
  {
    CHS: "",
    ENG: "Burundi",
    EGNFullName: "",
    ISO2: "BI"
  },
  {
    CHS: "柬埔寨",
    ENG: "Cambodia",
    EGNFullName: "the Kingdom of Cambodia",
    ISO2: "KH"
  },
  {
    CHS: "佛得角",
    ENG: "Cape Verde",
    EGNFullName: "the Republic of Cape Verde",
    ISO2: "CV"
  },
  {
    CHS: "",
    ENG: "Central African Republic",
    EGNFullName: "",
    ISO2: "CF"
  },
  {
    CHS: "乍得",
    ENG: "Chad",
    EGNFullName: "the Republic of Chad",
    ISO2: "TD"
  },
  {
    CHS: "智利",
    ENG: "Chile",
    EGNFullName: "the Republic of Chile",
    ISO2: "CL"
  },
  {
    CHS: "哥伦比亚",
    ENG: "Colombia",
    EGNFullName: "the Republic of Colombia",
    ISO2: "CO"
  },
  {
    CHS: "科摩罗",
    ENG: "Comoros",
    EGNFullName: "the Union of the Comoros",
    ISO2: "KM"
  },
  {
    CHS: "",
    ENG: "Congo (Brazzavile)",
    EGNFullName: "",
    ISO2: "CG"
  },
  {
    CHS: "",
    ENG: "Congo, Dem. Rep.",
    EGNFullName: "",
    ISO2: "CD"
  },
  {
    CHS: "哥斯达黎加",
    ENG: "Costa Rica",
    EGNFullName: "the Republic of Costa Rica",
    ISO2: "CR"
  },
  {
    CHS: "科特迪瓦",
    ENG: "Côte d'Ivoire",
    EGNFullName: "the Republic of Côte d'Ivoire",
    ISO2: "CI"
  },
  {
    CHS: "克罗地亚",
    ENG: "Croatia",
    EGNFullName: "the Republic of Croatia",
    ISO2: "HR"
  },
  {
    CHS: "",
    ENG: "Cuba",
    EGNFullName: "",
    ISO2: "CU"
  },
  {
    CHS: "",
    ENG: "Curacao",
    EGNFullName: "",
    ISO2: "CW"
  },
  {
    CHS: "吉布提",
    ENG: "Djibouti",
    EGNFullName: "the Republic of Djibouti",
    ISO2: "DJ"
  },
  {
    CHS: "多米尼加",
    ENG: "Dominican Republic",
    EGNFullName: "the Dominican Republic",
    ISO2: "DO"
  },
  {
    CHS: "厄瓜多尔",
    ENG: "Ecuador",
    EGNFullName: "the Republic of Ecuador",
    ISO2: "EC"
  },
  {
    CHS: "埃及",
    ENG: "Egypt",
    EGNFullName: "the Arab Republic of Egypt",
    ISO2: "EG"
  },
  {
    CHS: "萨尔瓦多",
    ENG: "El Salvador",
    EGNFullName: "the Republic of El Salvador",
    ISO2: "SV"
  },
  {
    CHS: "赤道几内亚",
    ENG: "Equatorial Guinea",
    EGNFullName: "the Republic of Equatorial Guinea",
    ISO2: "GQ"
  },
  {
    CHS: "厄立特里亚",
    ENG: "Eritrea",
    EGNFullName: "",
    ISO2: "ER"
  },
  {
    CHS: "加蓬",
    ENG: "Gabon",
    EGNFullName: "the Gabonese Republic",
    ISO2: "GA"
  },
  {
    CHS: "冈比亚",
    ENG: "Gambia",
    EGNFullName: "the Republic of The Gambia",
    ISO2: "GM"
  },
  {
    CHS: "加纳",
    ENG: "Ghana",
    EGNFullName: "the Republic of Ghana",
    ISO2: "GH"
  },
  {
    CHS: "格林纳达",
    ENG: "Grenada",
    EGNFullName: "",
    ISO2: "GD"
  },
  {
    CHS: "危地马拉",
    ENG: "Guatemala",
    EGNFullName: "the Republic of Guatemala",
    ISO2: "GT"
  },
  {
    CHS: "几内亚",
    ENG: "Guinea",
    EGNFullName: "the Republic of Guinea",
    ISO2: "GN"
  },
  {
    CHS: "几内亚比绍",
    ENG: "Guinea-Bissau",
    EGNFullName: "the Republic of Guinea-Bissau",
    ISO2: "GW"
  },
  {
    CHS: "圭亚那",
    ENG: "Guyana",
    EGNFullName: "the Republic of Guyana",
    ISO2: "GY"
  },
  {
    CHS: "海地",
    ENG: "Haiti",
    EGNFullName: "the Republic of Haiti",
    ISO2: "HT"
  },
  {
    CHS: "洪都拉斯",
    ENG: "Honduras",
    EGNFullName: "the Republic of Honduras",
    ISO2: "HN"
  },
  {
    CHS: "冰岛",
    ENG: "Iceland",
    EGNFullName: "the Republic of Iceland",
    ISO2: "IS"
  },
  {
    CHS: "印度",
    ENG: "India",
    EGNFullName: "the Republic of India",
    ISO2: "IN"
  },
  {
    CHS: "印度尼西亚",
    ENG: "Indonesia",
    EGNFullName: "the Republic of Indonesia",
    ISO2: "ID"
  },
  {
    CHS: "",
    ENG: "Iran",
    EGNFullName: "",
    ISO2: "IR"
  },
  {
    CHS: "",
    ENG: "Iraq",
    EGNFullName: "",
    ISO2: "IQ"
  },
  {
    CHS: "以色列",
    ENG: "Israel",
    EGNFullName: "the State of Israel",
    ISO2: "IL"
  },
  {
    CHS: "牙买加",
    ENG: "Jamaica",
    EGNFullName: "",
    ISO2: "JM"
  },
  {
    CHS: "约旦",
    ENG: "Jordan",
    EGNFullName: "the Hashemite Kingdom of Jordan",
    ISO2: "JO"
  },
  {
    CHS: "哈萨克斯坦",
    ENG: "Kazakhstan",
    EGNFullName: "the Republic of Kazakhstan",
    ISO2: "KZ"
  },
  {
    CHS: "肯尼亚",
    ENG: "Kenya",
    EGNFullName: "the Republic of Kenya",
    ISO2: "KE"
  },
  {
    CHS: "",
    ENG: "Korea, North",
    EGNFullName: "",
    ISO2: "KP"
  },
  {
    CHS: "",
    ENG: "Kosovo",
    EGNFullName: "",
    ISO2: "XK"
  },
  {
    CHS: "科威特",
    ENG: "Kuwait",
    EGNFullName: "he State of Kuwait",
    ISO2: "KW"
  },
  {
    CHS: "吉尔吉斯斯坦",
    ENG: "Kyrgyzstan",
    EGNFullName: "the Kyrgyz Republic",
    ISO2: "KG"
  },
  {
    CHS: "老挝",
    ENG: "Lao People's Democratic Republic",
    EGNFullName: "the Lao People's Democratic Republic",
    ISO2: "LA"
  },
  {
    CHS: "黎巴嫩",
    ENG: "Lebanon",
    EGNFullName: "the Lebanese Republic",
    ISO2: "LB"
  },
  {
    CHS: "莱索托",
    ENG: "Lesotho",
    EGNFullName: "the Kingdom of Lesotho",
    ISO2: "LS"
  },
  {
    CHS: "利比里亚",
    ENG: "Liberia",
    EGNFullName: "the Republic of Liberia",
    ISO2: "LR"
  },
  {
    CHS: "",
    ENG: "Libya",
    EGNFullName: "",
    ISO2: "LY"
  },
  {
    CHS: "澳门",
    ENG: "Macao",
    EGNFullName: "Macao Special Administrative Region of China",
    ISO2: "MO"
  },
  {
    CHS: "前南马其顿",
    ENG: "Macedonia (the former Yugoslav Republic of)",
    EGNFullName: "the former Yugoslav Republic of Macedonia",
    ISO2: "MK"
  },
  {
    CHS: "马达加斯加",
    ENG: "Madagascar",
    EGNFullName: "the Republic of Madagascar",
    ISO2: "MG"
  },
  {
    CHS: "马拉维",
    ENG: "Malawi",
    EGNFullName: "the Republic of Malawi",
    ISO2: "MW"
  },
  {
    CHS: "马来西亚",
    ENG: "Malaysia",
    EGNFullName: "",
    ISO2: "MY"
  },
  {
    CHS: "马尔代夫",
    ENG: "Maldives",
    EGNFullName: "the Republic of Maldives",
    ISO2: "MV"
  },
  {
    CHS: "马里",
    ENG: "Mali",
    EGNFullName: "the Republic of Mali",
    ISO2: "ML"
  },
  {
    CHS: "马耳他",
    ENG: "Malta",
    EGNFullName: "the Republic of Malta",
    ISO2: "MT"
  },
  {
    CHS: "马绍尔群岛",
    ENG: "Marshall Islands",
    EGNFullName: "the Republic of the Marshall Islands",
    ISO2: "MH"
  },
  {
    CHS: "毛利塔尼亚",
    ENG: "Mauritania",
    EGNFullName: "the Islamic Republic of Mauritania",
    ISO2: "MR"
  },
  {
    CHS: "毛里求斯",
    ENG: "Mauritius",
    EGNFullName: "the Republic of Mauritius",
    ISO2: "MU"
  },
  {
    CHS: "墨西哥",
    ENG: "Mexico",
    EGNFullName: "the United Mexican States",
    ISO2: "MX"
  },
  {
    CHS: "摩尔多瓦",
    ENG: "Moldova (the Republic of)",
    EGNFullName: "the Republic of Moldova",
    ISO2: "MD"
  },
  {
    CHS: "蒙古",
    ENG: "Mongolia",
    EGNFullName: "",
    ISO2: "MN"
  },
  {
    CHS: "黑山",
    ENG: "Montenegro",
    EGNFullName: "he Republic of Montenegro",
    ISO2: "ME"
  },
  {
    CHS: "摩洛哥",
    ENG: "Morocco",
    EGNFullName: "the Kingdom of Morocco",
    ISO2: "MA"
  },
  {
    CHS: "莫桑比克",
    ENG: "Mozambique",
    EGNFullName: "the Republic of Mozambique",
    ISO2: "MZ"
  },
  {
    CHS: "缅甸",
    ENG: "Myanmar",
    EGNFullName: "the Union of Myanmar",
    ISO2: "MM"
  },
  {
    CHS: "尼泊尔",
    ENG: "Nepal",
    EGNFullName: "Nepal",
    ISO2: "NP"
  },
  {
    CHS: "荷属安的列斯",
    ENG: "Netherlands Antilles",
    EGNFullName: "",
    ISO2: "AN"
  },
  {
    CHS: "尼加拉瓜",
    ENG: "Nicaragua",
    EGNFullName: "the Republic of Nicaragua",
    ISO2: "NI"
  },
  {
    CHS: "尼日尔",
    ENG: "Niger",
    EGNFullName: "the Republic of the Niger",
    ISO2: "NE"
  },
  {
    CHS: "尼日利亚",
    ENG: "Nigeria",
    EGNFullName: "the Federal Republic of Nigeria",
    ISO2: "NG"
  },
  {
    CHS: "巴基斯坦",
    ENG: "Pakistan",
    EGNFullName: "the Islamic Republic of Pakistan",
    ISO2: "PK"
  },
  {
    CHS: "巴勒斯坦",
    ENG: "Palestinian Territory (the Occupied)",
    EGNFullName: "the Occupied Palestinian Territory",
    ISO2: "PS"
  },
  {
    CHS: "巴拿马",
    ENG: "Panama",
    EGNFullName: "the Republic of Panama",
    ISO2: "PA"
  },
  {
    CHS: "巴布亚新几内亚",
    ENG: "Papua New Guinea",
    EGNFullName: "",
    ISO2: "PG"
  },
  {
    CHS: "巴拉圭",
    ENG: "Paraguay",
    EGNFullName: "the Republic of Paraguay",
    ISO2: "PY"
  },
  {
    CHS: "秘鲁",
    ENG: "Peru",
    EGNFullName: "the Republic of Peru",
    ISO2: "PE"
  },
  {
    CHS: "卡塔尔",
    ENG: "Qatar",
    EGNFullName: "the State of Qatar",
    ISO2: "QA"
  },
  {
    CHS: "",
    ENG: "Russia",
    EGNFullName: "",
    ISO2: "RU"
  },
  {
    CHS: "沙特阿拉伯",
    ENG: "Saudi Arabia",
    EGNFullName: "the Kingdom of Saudi Arabia",
    ISO2: "SA"
  },
  {
    CHS: "塞内加尔",
    ENG: "Senegal",
    EGNFullName: "the Republic of Senegal",
    ISO2: "SN"
  },
  {
    CHS: "塞尔维亚",
    ENG: "Serbia",
    EGNFullName: "the Republic of Serbia",
    ISO2: "RS"
  },
  {
    CHS: "塞舌尔",
    ENG: "Seychelles",
    EGNFullName: "the Republic of Seychelles",
    ISO2: "SC"
  },
  {
    CHS: "塞拉利昂",
    ENG: "Sierra Leone",
    EGNFullName: "the Republic of Sierra Leone",
    ISO2: "SL"
  },
  {
    CHS: "",
    ENG: "Somalia",
    EGNFullName: "",
    ISO2: "SO"
  },
  {
    CHS: "南非",
    ENG: "South Africa",
    EGNFullName: "the Republic of South Africa",
    ISO2: "ZA"
  },
  {
    CHS: "圣卢西亚",
    ENG: "Saint Lucia",
    EGNFullName: "",
    ISO2: "LC"
  },
  {
    CHS: "圣文森特和格林纳丁斯",
    ENG: "Saint Vincent and the Grenadines",
    EGNFullName: "",
    ISO2: "VC"
  },
  {
    CHS: "",
    ENG: "South Sudan",
    EGNFullName: "",
    ISO2: "SS"
  },
  {
    CHS: "",
    ENG: "Sudan (North)",
    EGNFullName: "",
    ISO2: "SD"
  },
  {
    CHS: "",
    ENG: "Sudan (South)",
    EGNFullName: "",
    ISO2: "SS"
  },
  {
    CHS: "斯威士兰",
    ENG: "Swaziland",
    EGNFullName: "the Kingdom of Swaziland",
    ISO2: "SZ"
  },
  {
    CHS: "",
    ENG: "Syria",
    EGNFullName: "",
    ISO2: "SY"
  },
  {
    CHS: "台湾",
    ENG: "Taiwan (Province of China)",
    EGNFullName: "",
    ISO2: "TW"
  },
  {
    CHS: "坦桑尼亚",
    ENG: "Tanzania,United Republic of",
    EGNFullName: "the United Republic of Tanzania",
    ISO2: "TZ"
  },
  {
    CHS: "多哥",
    ENG: "Togo",
    EGNFullName: "the Togolese Republic",
    ISO2: "TG"
  },
  {
    CHS: "特立尼达和多巴哥",
    ENG: "Trinidad and Tobago",
    EGNFullName: "the Republic of Trinidad and Tobago",
    ISO2: "TT"
  },
  {
    CHS: "突尼斯",
    ENG: "Tunisia",
    EGNFullName: "the Republic of Tunisia",
    ISO2: "TN"
  },
  {
    CHS: "土耳其",
    ENG: "Turkey",
    EGNFullName: "the Republic of Turkey",
    ISO2: "TR"
  },
  {
    CHS: "土库曼斯坦",
    ENG: "Turkmenistan",
    EGNFullName: "",
    ISO2: "TM"
  },
  {
    CHS: "特克斯和凯科斯群岛",
    ENG: "Turks and Caicos Islands",
    EGNFullName: "",
    ISO2: "TC"
  },
  {
    CHS: "乌干达",
    ENG: "Uganda",
    EGNFullName: "the Republic of Uganda",
    ISO2: "UG"
  },
  {
    CHS: "乌克兰",
    ENG: "Ukraine",
    EGNFullName: "",
    ISO2: "UA"
  },
  {
    CHS: "阿联酋",
    ENG: "United Arab Emirates",
    EGNFullName: "the United Arab Emirates",
    ISO2: "AE"
  },
  {
    CHS: "乌拉圭",
    ENG: "Uruguay",
    EGNFullName: "the Eastern Republic of Uruguay",
    ISO2: "UY"
  },
  {
    CHS: "瓦努阿图",
    ENG: "Vanuatu",
    EGNFullName: "the Republic of Vanuatu",
    ISO2: "VU"
  },
  {
    CHS: "",
    ENG: "Venezuela",
    EGNFullName: "",
    ISO2: "VE"
  },
  {
    CHS: "越南",
    ENG: "Viet Nam",
    EGNFullName: "the Socialist Republic of Viet Nam",
    ISO2: "VN"
  },
  {
    CHS: "",
    ENG: "Yemen",
    EGNFullName: "",
    ISO2: "YE"
  },
  {
    CHS: "",
    ENG: "Zimbabwe",
    EGNFullName: "",
    ISO2: "ZW"
  }
];

export { highRiskCountryList };
