// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Index_home_1OUPi{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap;flex-flow:column nowrap}.Index_rfq_2R1py{-webkit-box-shadow:0 0 20px rgba(235,240,245,.5);box-shadow:0 0 20px rgba(235,240,245,.5)}", ""]);
// Exports
exports.locals = {
	"home": "Index_home_1OUPi",
	"rfq": "Index_rfq_2R1py"
};
module.exports = exports;
