<template>
  <section>
    <a-collapse v-page-guide="'debug.guide_messages[1]'" :class="$style.nav">
      <a-collapse-panel header="router list">
        <nav>
          <router-link v-for="route in navRoutes" :to="route" :key="route.name">
            [ {{ route.name }} ]
          </router-link>
        </nav>
      </a-collapse-panel>
    </a-collapse>

    <section v-page-guide="'debug.guide_messages[2]'" :class="$style.debug">
      <v-swatches
        :class="$style.swatches"
        :fallback-input-class="$style.swatchesInput"
        :fallback-ok-class="$style.swatchesOk"
        v-model="color"
        show-fallback
        shapes="circles"
        swatch-size="32"
        :trigger-style="{ height: '32px', width: '32px' }"
        fallback-input-type="color"
      />
      <a-radio-group v-model="size" size="small" buttonStyle="solid">
        <a-radio-button
          v-for="item in sizeList"
          :key="`size-${item}`"
          :value="item"
          >{{ $t(`debug.size.${item}`) }}</a-radio-button
        >
      </a-radio-group>
      <a-radio-group v-model="$i18n.locale" size="small" buttonStyle="solid">
        <a-radio-button
          v-for="lang in langs"
          :key="`lang-${lang}`"
          :value="lang"
          >{{ $t(`debug.lang.${lang}`) }}</a-radio-button
        >
      </a-radio-group>
      <a-radio-group
        v-if="isStatusRadioVisible"
        v-model="accountStatus"
        v-page-guide="'debug.guide_messages[3]'"
        :disabled="isLoading"
        size="small"
        buttonStyle="solid"
      >
        <a-radio-button
          v-for="item in status"
          :key="`status-${item}`"
          :value="item"
          >{{ $t(`debug.status.${item}`) }}</a-radio-button
        >
      </a-radio-group>
      <a-switch v-model="noLegend" v-page-guide="'debug.guide_messages[5]'">
        <logo-legend slot="unCheckedChildren" />
        <a-icon slot="checkedChildren" type="stop" />
      </a-switch>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Collapse, Icon, Radio, Switch } from "ant-design-vue";
import VSwatches from "vue-swatches/src/VSwatches.vue";
import LogoLegend from "@/assets/img/logo.svg?inline";
import { sizeList } from "@/utils";

export default {
  name: "DebugPanel",
  components: {
    [Collapse.name]: Collapse,
    [Collapse.Panel.name]: Collapse.Panel,
    [Icon.name]: Icon,
    [Radio.Button.name]: Radio.Button,
    [Radio.Group.name]: Radio.Group,
    [Switch.name]: Switch,
    VSwatches,
    LogoLegend
  },
  data() {
    return {
      sizeList,
      langs: ["en", "zh"],
      status: ["disabled", "pending", "enabled"]
    };
  },
  computed: {
    ...mapState(["isLoading", "themeColor", "clientInfo"]),
    ...mapGetters(["isAccountEnabled", "isAccountPending"]),
    color: {
      get() {
        return this.themeColor;
      },
      set(val) {
        this.setThemeColor(val);
      }
    },
    size: {
      get() {
        return this.$store.state.clientInfo.uiSize;
      },
      set(val) {
        this.setUiSize(val);
      }
    },
    navRoutes() {
      const { options } = this.$router;
      return options.routes.filter(n => n.path !== "*");
    },
    isStatusRadioVisible() {
      return this.$route.name === "trade.index";
    },
    accountStatus: {
      get() {
        if (this.isAccountPending) {
          return "pending";
        } else if (this.isAccountEnabled) {
          return "enabled";
        } else {
          return "disabled";
        }
      },
      set(val) {
        if (val === "pending") {
          this.updateKycStatus("Submitted");
        } else if (val === "enabled") {
          this.updateKycStatus("Approved");
        } else {
          this.updateKycStatus("Not Started");
        }
      }
    },
    noLegend: {
      get() {
        return this.clientInfo.noLegend;
      },
      set(noLegend) {
        this.updateClientInfo({ noLegend });
      }
    }
  },
  methods: {
    ...mapActions([
      "updateKycStatus",
      "updateClientInfo",
      "setThemeColor",
      "setUiSize"
    ])
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.nav {
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 1000;

  & nav {
    align-items: flex-end;
    display: flex;
    flex-flow: column nowrap;
    margin: -16px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 8px;
  }
  & a[href] {
    color: var(--ayanami);
    &:global(.router-link-active),
    &:global(.router-link-exact-active) {
      color: var(--red);
    }
  }
}
.debug {
  align-items: flex-end;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  .swatches,
  :global(.ant-radio-group),
  :global(.ant-switch) {
    margin-top: 0.25rem;
  }

  .swatches {
    display: flex;
    :global {
      .vue-swatches__wrapper {
        box-sizing: content-box;
      }
      .vue-swatches__fallback__wrapper {
        align-items: stretch;
        display: flex;
      }
    }
    .swatchesInput {
      height: 100%;
      padding: 0;
    }
    .swatchesOk {
      background-color: var(--legend);
    }
  }
}
</style>
