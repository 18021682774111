import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Mock from "mockjs";
import MockAdapter from "axios-mock-adapter";
import arrLogFunc, { apiConfig } from "../utils";
import {
  tmplReadGeneralInfo,
  tmplReadRepresentatives,
  tmplReadBeneficialOwners,
  tmplReadDocuments,
  tmplCompanyBankingInfo,
  tmplCompanyQuestionnaire,
  tmplReadIdentification,
  tmplGetAccountCustomSupport
} from "./mock";

let configPairs = null;

const initMockAdapter = api => {
  if (process.env.NODE_ENV === "production") {
    return;
  }

  api.interceptors.response.use(...arrLogFunc);

  const mock = new MockAdapter(api, {
    // delayResponse: 2000
  });

  // mock.onPost('/trade').networkErrorOnce();
  mock
    .onGet("/users")
    .reply(200, {
      users: [{ id: 1, name: "John Smith" }]
    })
    // .onPost("/institution/gi")
    // .reply(200)
    // .onPost("/institution/rep")
    // .reply(200)
    .onGet("/institution/gi")
    .reply(200, Mock.mock(tmplReadGeneralInfo))
    .onGet("/institution/rep")
    .reply(200, Mock.mock(tmplReadRepresentatives))
    .onGet("/institution/bo")
    .reply(200, Mock.mock(tmplReadBeneficialOwners))
    .onGet("/institution/doc")
    .reply(200, Mock.mock(tmplReadDocuments))
    .onGet("/institution/banking")
    .reply(200, Mock.mock(tmplCompanyBankingInfo))
    .onGet("/institution/qa")
    .reply(200, Mock.mock(tmplCompanyQuestionnaire))
    .onGet("/individual/id")
    .reply(200, Mock.mock(tmplReadIdentification))
    .onGet("/account/cs")
    .reply(200, Mock.mock(tmplGetAccountCustomSupport))
    .onAny()
    .passThrough();
};

const api = axios.create({
  baseURL: apiConfig.baseUrlKyc,
  headers: {
    Authorization: `Bearer ${apiConfig.token}`,
    Accept: "application/json"
  },
  timeout: 180000
});

if (apiConfig.reHostname.test(window.location.hostname)) {
  initMockAdapter(api);
}

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${apiConfig.token}`;
  return config;
});

const getPairs = async () => {
  if (!configPairs) {
    const resp = await api.get("/pairs");
    configPairs = resp.data;
  }
  return configPairs;
};

const rfq = async ({ pair = "BTCUSD", side = "buy", quantity = 1 }) => {
  const resp = await api.post("/rfq", {
    client_rfq_id: uuidv4(),
    pair,
    side,
    quantity
  });
  return resp.data;
};

//个人信息表单提交
const submitPersonInfo = async data => {
  const resp = await api.post("/individual/pi", data);
  return resp.data;
};
//查询个人信息表单提交
const getPersonInfo = async () => {
  const resp = await api.get("/individual/pi");
  return resp.data;
};
// 提交个人认证表单
const submitIdentification = async data => {
  const resp = await api.post("/individual/id", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
// 获取个人认证表单数据
const getIdentification = async () => {
  const resp = await api.get("/individual/id");
  return resp.data;
};

//银行信息表单提交
const submitBankigInfo = async data => {
  const resp = await api.post("/individual/banking", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//查询银行信息数据
const getBankingInfo = async () => {
  const resp = await api.get("/individual/banking");
  return resp.data;
};

//补充信息表单提交
const submitQuestionnaire = async ({
  pep_is_officer = "No",
  pep_related_officer = "No",
  net_wealth_source = "",
  income_source = "",
  employment = "",
  comments = ""
}) => {
  const resp = await api.post("/individual/qa", {
    pep_is_officer,
    pep_related_officer,
    net_wealth_source,
    income_source,
    employment,
    comments
  });
  return resp.data;
};
//查询补充信息数据
const getQuestionnaire = async () => {
  const resp = await api.get("/individual/qa");
  return resp.data;
};

const doSubmit = async () => {
  const resp = await api.post("/submit");
  return resp.data;
};
//获取公司基本信息
const getGeneralInfo = async () => {
  const resp = await api.get("/institution/gi");
  return resp.data;
};
//提交公司基本信息
const submitGeneralInfo = async ({
  account_email = "",
  entity_legal_name = "",
  entity_legal_identifier = "",
  country = "",
  business_address = "",
  city = "",
  state = "",
  zipcode = "",
  date_of_formation = "",
  phone_country_code = "",
  phone_country_code_short = "",
  phone_number = "",
  company_website = ""
}) => {
  const resp = await api.post("/institution/gi", {
    account_email,
    entity_legal_name,
    entity_legal_identifier,
    country,
    business_address,
    city,
    state,
    zipcode,
    date_of_formation,
    phone_country_code,
    phone_country_code_short,
    phone_number,
    company_website
  });
  return resp.data;
};
//获取公司代表信息
const getRepresentives = async () => {
  const resp = await api.get("/institution/rep");
  return resp.data;
};
//提交公司代表信息
const submitRepresentives = async data => {
  const resp = await api.post("/institution/rep", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//获取公司最终受益人信息
const getBeneficialOwnersInfo = async () => {
  const resp = await api.get("/institution/bo");
  return resp.data;
};
//提交公司最终受益人信息
const submitBeneficialOwnersInfo = async data => {
  const resp = await api.post("/institution/bo", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//获取公司文件上传信息
const getDocumentsInfo = async () => {
  const resp = await api.get("/institution/doc");
  return resp.data;
};
//提交公司文件上传信息
const submitDocumentsInfo = async data => {
  const resp = await api.post("/institution/doc", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//获取公司银行信息
const getCompanyBankingInfo = async () => {
  const resp = await api.get("/institution/banking");
  return resp.data;
};
//提交公司银行信息
const submitCompanyBankingInfo = async data => {
  const resp = await api.post("/institution/banking", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};
//获取公司补充信息
const getCompanyQuestionnaire = async () => {
  const resp = await api.get("/institution/qa");
  return resp.data;
};
//提交公司补充信息
const submitCompanyQuestionnarie = async ({
  connect_with_sanctioned_countries = "No",
  is_officer = "No",
  related_officer = "No",
  purpose = "",
  nature_of_business = "",
  source_of_funds = "",
  comments = ""
}) => {
  const resp = await api.post("/institution/qa", {
    connect_with_sanctioned_countries,
    is_officer,
    related_officer,
    purpose,
    nature_of_business,
    source_of_funds,
    comments
  });
  return resp.data;
};

const getAccountCustomSupport = async () => {
  const resp = await api.get("/account/cs");
  return resp.data;
};

const getFees = async () => {
  const resp = await api.get("/fees");
  return resp.data;
};

const uploadFile = async formData => {
  const resp = await api.post("/userfiles", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
};

const getVeriffUrl = async (verification_type = "individual_id") => {
  const resp = await api.post("/veriff", { verification_type });
  return resp.data;
};

const getVeriffStatus = async (verification_type = "individual_id") => {
  const resp = await api.get("/veriff", {
    params: { verification_type }
  });
  return resp.data;
};

export {
  api,
  getPairs,
  rfq,
  submitPersonInfo,
  getPersonInfo,
  submitIdentification,
  getIdentification,
  submitBankigInfo,
  getBankingInfo,
  submitQuestionnaire,
  getQuestionnaire,
  getGeneralInfo,
  submitGeneralInfo,
  getRepresentives,
  submitRepresentives,
  getBeneficialOwnersInfo,
  submitBeneficialOwnersInfo,
  getDocumentsInfo,
  submitDocumentsInfo,
  getCompanyBankingInfo,
  submitCompanyBankingInfo,
  getCompanyQuestionnaire,
  submitCompanyQuestionnarie,
  doSubmit,
  getAccountCustomSupport,
  getFees,
  uploadFile,
  getVeriffUrl,
  getVeriffStatus
};
