import { required, email, numeric, alpha_num } from "vee-validate/dist/rules";
import { extend, configure } from "vee-validate";
import i18n from "./i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    switch (values._rule_) {
      case "required": {
        return i18n.t("is Empty", [values._field_]);
      }
      case "email": {
        return i18n.t("Incorrect email format");
      }
      case "numeric": {
        return i18n.t("please enter numeric");
      }
      case "alpha_num": {
        return i18n.t("Incorrect passport format");
      }
    }
  }
});

extend("required", required);

extend("email", email);

extend("numeric", numeric);

extend("alpha_num", alpha_num);

extend("stateCheck", value => {
  const invalidArray = ["NY", "NEWYORK", "纽约"];
  let bRet = true;
  invalidArray.forEach(item => {
    let tempValue = value.replace(/\s*/g, "");
    if (item === tempValue.toUpperCase()) {
      bRet = false;
    }
  });
  if (!bRet) {
    return i18n.t("State Check MSG");
  }
  return true;
});

extend("cityCheck", value => {
  const invalidArray = ["NY", "NEWYORK", "纽约", "NEWYORKCITY"];
  let bRet = true;
  invalidArray.forEach(item => {
    let tempValue = value.replace(/\s*/g, "");
    if (item === tempValue.toUpperCase()) {
      bRet = false;
    }
  });
  if (!bRet) {
    return i18n.t("State Check MSG");
  }
  return true;
});

extend("alpha_address", value => {
  const reg = /\p{Unified_Ideograph}/u;
  if (reg.test(value)) {
    return i18n.t("Receipt Address");
  }
  return true;
});

extend("alpha_num_space", value => {
  const reg = /^[A-Za-z0-9 ]+$/;
  if (!reg.test(value)) {
    return i18n.t("Receipt Bank Account Number");
  }
  return true;
});
