// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmModal_modal_1zTaz .ConfirmModal_title_1zYTR{font-size:16px;color:rgba(0,0,0,.85);font-weight:500;margin-bottom:8px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ConfirmModal_modal_1zTaz .ConfirmModal_title_1zYTR .ConfirmModal_warn_11uf2{font-size:22px;margin-right:16px;color:#faad14}.ConfirmModal_modal_1zTaz .ConfirmModal_content_1_D5q{margin-left:40px;font-size:14px}.ConfirmModal_modal_1zTaz .ConfirmModal_footer_1wlSt{text-align:right;margin-top:10px}.ConfirmModal_modal_1zTaz .ConfirmModal_footer_1wlSt .ConfirmModal_btnOk_2pxjx{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"modal": "ConfirmModal_modal_1zTaz",
	"title": "ConfirmModal_title_1zYTR",
	"warn": "ConfirmModal_warn_11uf2",
	"content": "ConfirmModal_content_1_D5q",
	"footer": "ConfirmModal_footer_1wlSt",
	"btnOk": "ConfirmModal_btnOk_2pxjx"
};
module.exports = exports;
