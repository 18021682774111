// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/list-item.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".FaqWhy_faq_2VVCm{padding-top:calc(1.325rem + .9vw)}@media(min-width:1200px){.FaqWhy_faq_2VVCm{padding-top:2rem}}.FaqWhy_faq_2VVCm>h4{font-weight:600;margin-top:2rem}.FaqWhy_faq_2VVCm>h4 span{color:var(--primary);cursor:pointer}.FaqWhy_faq_2VVCm>h4 span:focus,.FaqWhy_faq_2VVCm>h4 span:hover{color:var(--primary-5)}.FaqWhy_faq_2VVCm>h4 span.FaqWhy_active_38ppw,.FaqWhy_faq_2VVCm>h4 span:active{color:var(--primary-7)}.FaqWhy_faq_2VVCm .ant-collapse-borderless{background-color:transparent}.FaqWhy_faq_2VVCm .ant-collapse-borderless>.ant-collapse-item{border-bottom:0 none}.FaqWhy_faq_2VVCm .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-header{display:none}.FaqWhy_why_1etGB{list-style-position:inside;padding:1rem 0 0}.FaqWhy_why_1etGB.FaqWhy_legend_1AOiL{list-style-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.FaqWhy_why_1etGB li{margin-top:.25rem}", ""]);
// Exports
exports.locals = {
	"faq": "FaqWhy_faq_2VVCm",
	"active": "FaqWhy_active_38ppw",
	"why": "FaqWhy_why_1etGB",
	"legend": "FaqWhy_legend_1AOiL"
};
module.exports = exports;
