<template>
  <a-divider orientation="left">
    <a-breadcrumb :routes="fullRoutes">
      <template slot="itemRender" slot-scope="{ route, routes }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          <a-icon v-if="route.icon" :type="route.icon" />
          <template v-else-if="routes.indexOf(route) < 2">
            <slot name="title">OTC Trading</slot> - {{ route.name }}
          </template>
          <template v-else>
            {{ route.name }}
          </template>
        </span>
        <router-link v-else :to="route.path">
          <a-icon v-if="route.icon" :type="route.icon" />
          <template v-else-if="routes.indexOf(route) < 2">
            <slot name="title">OTC Trading</slot> - {{ route.name }}
          </template>
          <template v-else>
            {{ route.name }}
          </template>
        </router-link>
      </template>
    </a-breadcrumb>
  </a-divider>
</template>

<script>
import { Breadcrumb, Divider, Icon } from "ant-design-vue";

export default {
  name: "TopNav",
  components: {
    [Breadcrumb.name]: Breadcrumb,
    [Breadcrumb.Item.name]: Breadcrumb.Item,
    [Divider.name]: Divider,
    [Icon.name]: Icon
  },
  data() {
    return {
      homeRoute: {
        path: "/trade",
        icon: "home",
        name: "Home"
      }
    };
  },
  props: {
    currRouteName: {
      type: String,
      default() {
        return this.$route.name;
      }
    }
  },
  computed: {
    fullRoutes() {
      const { path, icon, name } = this.homeRoute;
      if (this.$route.name === "trade.index") {
        return [{ path, name }];
      } else if (this.$route.name === "trade.deposit.guide") {
        return [
          { path, icon },
          { path: "/deposit", name: "Deposit" },
          { name: this.currRouteName }
        ];
      } else {
        return [{ path, icon }, { name: this.currRouteName }];
      }
    }
  },
  methods: {}
};
</script>
