import Vue from "vue";
import Vuex from "vuex";

const state = {
  isLoading: false,
  maintenance: false,
  themeColor: "#b39058",
  kycStatus: "Not Started", // Not Started, Started, In Progress, Submitted, In Review, Approved, Rejected
  accountEmail: "",
  accountType: "", // Individual, Entity
  expiresIn: 0,
  token: "",
  pairs: {},
  clientInfo: {
    mode: "",
    email: "",
    noLegend: false,
    uiSize: "default",
    primaryColor: "",
    urlAssets: "", // use "INTERNAL" for routing of trade.wallet
    urlDeposit: "", // use "INTERNAL" for routing of trade.wallet
    timestamp: "",
    signature: "",
    appId: "",
    appUid: "",
    appUrl: "",
    appKey: ""
  }
};

const TYPES = {
  TOGGLE_LOADING: "TOGGLE_LOADING",
  TOGGLE_MAINTENANCE: "TOGGLE_MAINTENANCE",
  UPDATE_KYC_STATUS: "UPDATE_KYC_STATUS",
  UPDATE_ACCOUNT_EMAIL: "UPDATE_ACCOUNT_EMAIL",
  UPDATE_ACCOUNT_TYPE: "UPDATE_ACCOUNT_TYPE",
  UPDATE_EXPIRES_IN: "UPDATE_EXPIRES_IN",
  UPDATE_CLIENT_INFO: "UPDATE_CLIENT_INFO",
  SET_THEME_COLOR: "SET_THEME_COLOR",
  SET_UI_SIZE: "SET_UI_SIZE",
  SET_TOKEN: "SET_TOKEN",
  SET_PAIRS: "SET_PAIRS"
};

const mutations = {
  [TYPES.TOGGLE_LOADING](state, value) {
    if (typeof value === "boolean") {
      state.isLoading = value;
    } else {
      state.isLoading = !state.isLoading;
    }
  },
  [TYPES.TOGGLE_MAINTENANCE](state, value) {
    if (typeof value === "boolean") {
      state.maintenance = value;
    } else {
      state.maintenance = !state.maintenance;
    }
  },
  [TYPES.UPDATE_KYC_STATUS](state, value) {
    state.kycStatus = value;
  },
  [TYPES.UPDATE_ACCOUNT_EMAIL](state, value = "") {
    state.accountEmail = value;
  },
  [TYPES.UPDATE_ACCOUNT_TYPE](state, value) {
    state.accountType = value;
  },
  [TYPES.UPDATE_EXPIRES_IN](state, value = 0) {
    state.expiresIn = value;
  },
  [TYPES.UPDATE_CLIENT_INFO](state, value) {
    if (value.urlAssets === "") {
      if (value.appId === "Bitrue") {
        value.urlAssets = "https://www.bitrue.com/assets";
      } else {
        // eslint-disable-next-line
        console.error("[url-assets] is unset, fallback with [app-url].");
        value.urlAssets = value.appUrl;
      }
    }
    if (value.urlDeposit === "") {
      if (value.appId === "Bitrue") {
        value.urlDeposit =
          "https://www.bitrue.com/assets/deposit?symbol={{SYMBOL}}";
      } else {
        // eslint-disable-next-line
        console.error("[url-deposit] is unset, fallback with [app-url].");
        value.urlDeposit = value.appUrl;
      }
    }

    state.clientInfo = { ...state.clientInfo, ...value };
  },
  [TYPES.SET_THEME_COLOR](state, value) {
    state.themeColor = value;
  },
  [TYPES.SET_UI_SIZE](state, value) {
    state.clientInfo.uiSize = value;
  },
  [TYPES.SET_TOKEN](state, value) {
    state.token = value;
  },
  [TYPES.SET_PAIRS](state, value) {
    state.pairs = value;
  }
};

const actions = {
  toggleLoading({ commit }, value) {
    commit(TYPES.TOGGLE_LOADING, value);
  },
  toggleMaintenance({ commit }, value) {
    commit(TYPES.TOGGLE_MAINTENANCE, value);
  },
  updateKycStatus({ commit }, value) {
    commit(TYPES.UPDATE_KYC_STATUS, value);
  },
  updateAccountEmail({ commit }, value) {
    commit(TYPES.UPDATE_ACCOUNT_EMAIL, value);
  },
  updateAccountType({ commit }, value) {
    commit(TYPES.UPDATE_ACCOUNT_TYPE, value);
  },
  updateExpiresIn({ commit }, value) {
    commit(TYPES.UPDATE_EXPIRES_IN, value);
  },
  updateClientInfo({ commit }, value) {
    commit(TYPES.UPDATE_CLIENT_INFO, value);
  },
  setThemeColor({ commit }, value) {
    commit(TYPES.SET_THEME_COLOR, value);
  },
  setUiSize({ commit }, value) {
    commit(TYPES.SET_UI_SIZE, value);
  },
  setToken({ commit }, value) {
    commit(TYPES.SET_TOKEN, value);
  },
  setPairs({ commit }, value) {
    commit(TYPES.SET_PAIRS, value);
  }
};

const getters = {
  urlAssets: state => state.clientInfo.urlAssets,
  urlDeposit: state => state.clientInfo.urlDeposit,
  isAccountEnabled: ({ kycStatus }) => ["Approved"].includes(kycStatus),
  isAccountPending: ({ kycStatus }) =>
    ["Submitted", "In Review"].includes(kycStatus)
};

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
